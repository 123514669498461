import React, { useState, useContext } from "react";
import {UserContext} from './UserContext';

export default function Login(){

    const {loginUser, loggedInCheck} = useContext(UserContext);
    const [message, setMessage] = useState(false);
    const [intro, setIntro] = useState(true);

    const [formData, setFormData] = useState(
        {
            username: '',
            password: ''
        }
    );

    function formChange(event){
        const {name, value} = event.target;
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    const sendLogin = async(event) => {
        setMessage('');
        event.preventDefault();
        if(!formData.username || !formData.password){
            setMessage('Proszę wypełnić oba pola');
        } else {
            const data = await loginUser(formData);
            setMessage(data.message);
            await loggedInCheck();
            return;
        }
    }

    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = () => {
        setIsInputFocused(true);
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
    }

    return (
        <>
        {intro && <div id="intro-outer-container">
            <div id="intro-inner-container">
                <button id="primary-login" onClick={() => setIntro(false)}>Logowanie</button>
                {/*    
                <img
                    src={Logo}
                    alt='logo'
                    className="logo"
                />
                    */}
            </div>
        </div>}
        {!intro &&
        <div id="login-outer-container">
            <div id="login-inner-container" className={isInputFocused === true ? "login-inner-container" : ''}>
                <div id="login-inner-1">
                    <div id="login-inner-2">
                        <div id="login">
                            <div className="login-field">
                                <input 
                                    className="login-form"
                                    id="username"
                                    type="text"
                                    onChange={formChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    name="username"
                                    value={formData.username}
                                    placeholder="Nazwa użytkownika"
                                />
                            </div>
                            <div className="login-field">
                                <input 
                                    className="login-form"
                                    id="password"
                                    type="password"
                                    onChange={formChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    name="password"
                                    value={formData.password}
                                    placeholder="Hasło"
                                />
                            </div>
                            <p id="login-button-wrapper">
                                <button id="send-login" onClick={sendLogin}><span id="send-login-text">Zaloguj się</span></button>	
                                {message && <span id="login-message">{message}</span>}
                            </p>
                        </div>
                    </div>
                    <div id="login-background">
                        <span id="login-background-shape4" className="login-background-shape"></span>
                        <span id="login-background-shape3" className="login-background-shape"></span>		
                        <span id="login-background-shape2" className="login-background-shape"></span>
                        <span id="login-background-shape1" className="login-background-shape"></span>
                    </div>		
	            </div>
            </div>
        </div>
        }
        </>
    );
};  