import {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { db } from "./Db";

export const UserContext = createContext();
/*
export const Axios = axios.create({
    baseURL: '/php-traffic/',
});

baseURL: 'https://localhost/php-traffic/',
*/
export const Axios = axios.create({
    baseURL: 'php/',
});

export const UserContextProvider = ({children}) => {

    const [theUser, setUser] = useState(null);

    const [wait, setWait] = useState(false);

    // Pobieranie użytkownika z lokalnej bazy danych

    const [userDB, setUserDB] = useState([]);

    useEffect(() => {
        db.user.toArray().then(function(result){
            setUserDB(result);
        });
    },[]);

    // Logowanie

    const loginUser = async (formData) => {
        setWait(true);
        try{
            const {data} = await Axios.post('login.php', {formData});
            if(data.token){
                if(userDB.length === 0){
                    createUser(data);                    
                } else {
                    updateUser(data);
                }
                setUserDB([{username: data.user, personal_id: data.personal_id, token: data.token}]);
                setWait(false);
                return {message:'Logowanie udane.'};
            }
            setWait(false);
            return {message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Błąd serwera!'};
        }
    }

    // Wpisywanie użytkownika do lokalnej bazy danych

    const createUser  = async (data)=>{
        await db.user.put({index: 1, username: data.user, personal_id: data.personal_id, token: data.token});
    }

    // Aktualizacja wpisu użytkownika do lokalnej bazy danych

    const updateUser  = async (data)=>{
        await db.user.update(1, {username: data.user, personal_id: data.personal_id, token: data.token});
    }

    // Sprawdzanie użytkownika wpisanego do lokalnej bazy danych

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[userDB]);

    const loggedInCheck = async () => {

        if(userDB.length > 0){
            Axios.options('getUser.php', { timeout: 1500 }).then(function(){
                logOnline();
            }).catch((error) => {
                console.log(error);
                logOffline();
            });
        } else {
            setUser({name: '', role: 'none', personal_id: ''});
        }
        
    }

    const logOnline = async () => {

        const loginToken = userDB[0].token;

        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        if(loginToken){
            const {data} = await Axios.get('getUser.php');
            if(data.success && data.user){
                let userData = data.user[0];
                setUser({name: userData.username, personal_id: userData.personal_id, role: userData.role});
                return;
            } else if(data.success === 0 && data.message === "User not found"){
                setUser({name: '', role: 'none', personal_id: ''});
            } else {
                setUser({name: '', role: 'none', personal_id: ''});
                logOffline();
            }
        } else {
            setUser({name: '', role: 'none', personal_id: ''});
            logOffline();
        }

    }

    function logOffline(){
        if(userDB.length >0){
            let userData = userDB[0];
            setUser({name: userData.username, role: 'user', personal_id: userData.personal_id});
        }
    }

    // Wylogowanie

    const logout = () => {
        db.user.clear();
        setUser(null);
        window.location.reload(true);
    }

    return (
        <UserContext.Provider value={{loginUser,wait, user:theUser,loggedInCheck,logout}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;