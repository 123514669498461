import React, { useState, useEffect, useContext } from "react"; //useRef, 
import { UserContext, Axios } from './UserContext';
import { db } from "./Db";
import { Link } from 'react-router-dom';
import {ReactComponent as Gear} from '../images/gear.svg';
import Icon0 from '../images/icon0.png';
import {ReactComponent as Icon1} from '../images/icon1.svg';
import {ReactComponent as Icon2} from '../images/icon2.svg';
import {ReactComponent as Icon3} from '../images/icon3.svg';
import {ReactComponent as Icon4} from '../images/icon4.svg';
import {ReactComponent as Icon5} from '../images/icon5.svg';
import {ReactComponent as Icon6} from '../images/icon6.svg';
import {ReactComponent as Icon7} from '../images/icon7.svg';
import {ReactComponent as Icon8} from '../images/icon8.svg';
import {ReactComponent as Icon9} from '../images/icon9.svg';
import {ReactComponent as Icon10} from '../images/icon10.svg';
import {ReactComponent as Icon11} from '../images/icon11.svg';
import {ReactComponent as Icon12} from '../images/icon12.svg';
import {ReactComponent as Hamburger} from '../images/hamburger.svg';
import TrainFront from '../images/train-front.png';
import TrainCar from '../images/train-car.png';
import Instruction01 from '../images/instruction01.jpg';
import Instruction02 from '../images/instruction02.jpg';
import Instruction03 from '../images/instruction03.jpg';
import Instruction04 from '../images/instruction04.jpg';
import Instruction05 from '../images/instruction05.jpg';
import Instruction06 from '../images/instruction06.jpg';
import Instruction07 from '../images/instruction07.jpg';
import Instruction08 from '../images/instruction08.jpg';
import Instruction09 from '../images/instruction09.jpg';
import Instruction10 from '../images/instruction10.jpg';
import Instruction11 from '../images/instruction11.jpg';
import Instruction12 from '../images/instruction12.jpg';
import Instruction13 from '../images/instruction13.jpg';
import Instruction14 from '../images/instruction14.jpg';
import Instruction15 from '../images/instruction15.jpg';
import Instruction16 from '../images/instruction16.jpg';
import Instruction17 from '../images/instruction17.jpg';
import Instruction18 from '../images/instruction18.jpg';

export default function Main(){

    // --- Użytkownik i wylogowanie ---

    const {user} = useContext(UserContext);

    const {logout} = useContext(UserContext);

    // Przełącznik menu

    const [appLayer, setAppLayer] = useState(100);

    // --- Zadanie pomiarowe ---

    // Aktywne zadanie

    const [active, setActive] = useState({
        cancel: '',
        job_date: '',
        job_number: '',
        train_id: '',
        train_number: '',
        total_cars: '',
        first_car: '',
        last_car: '',
        relation: '',
        start_hour: '',
        end_hour: '',
        status: ''
    });

    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {

       if(active.job_date !== ''){

            const dateParts = active.job_date.split('-');
            setFormattedDate(`${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`);

       }
        
    }, [active.job_date]);

    // Główne obiekty pobrane bezpośrednio z bazy danych

    // Wszystkie zadania przyporządkowane do pracownika

    const [jobs, setJobs] = useState([]);

    // Wszystkie kursy przyporządkowane do pracownika

    const [rides, setRides] = useState([]);

    // Wszystkie pociągi przyporządkowane do pracownika

    const [trains, setTrains] = useState([]);

    // Wszystkie stacje przyporządkowane do pracownika

    const [stations, setStations] = useState([]);

    // Wszystkie numery pociągów przyporządkowane do pociągu

    const [trainNumbers, setTrainNumbers] = useState([]);

    // ----------------------------------------------------

    // Zadania przyporządkowane do wybranej wcześniej daty

    const [separatedJobs, setSeparatedJobs] = useState([]);

    // Nazwy zadań

    const [jobsNames, setJobsNames] = useState([]);

    // Daty zadań

    const [jobsDates, setJobsDates] = useState([]);

    // Wybrane zadanie

    const [activeJob, setActiveJob] = useState([]);

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);

    // Aktywne kursy przyporządkowane do wybranego zadania

    const [activeRides, setActiveRides] = useState([]);

    // Id pociągów w aktywnych kursach

    const [activeTrainIds, setActiveTrainIds] = useState([]);

    // Aktywne pociągi na podstawie zadania

    const [activeTrains, setActiveTrains] = useState([]);

    // Obiekt zawierający listę stacji do pomiaru

    const [measurementStations, setMeasurementStations] = useState([]);

    // Obiekt zawierający wszystkie pomiary

    const [measurementFormData, setMeasurementFormData] = useState({});

    // Obiekt zawierający informacje o skończonych etapach

    const [completedMeasurements, setCompletedMeasurements] = useState([]);

    // Poprzedni pociąg

    const [previousTrain, setPreviousTrain] = useState(false);

    // Zapełnienie

    const [fill, setFill] = useState(undefined);

    // --- Lokalizacja i pozyskiwanie zgody ---

    // Lokalizacja użytkownika

    const [userCoordinates, setUserCooridinates] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    // Stan zgody na lokalizację

    const [permissionsState, setPermissionsState] = useState(
        {
            location: ""
        }
    );

    const [prevPermissionsState, setPrevPermissionsState] = useState(
        {
            now: "",
            past: ""
        }
    );

    // Pozyskiwanie aktualnej lokalizacji

    /*

    function getLocation(){
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserCooridinates({lat: position.coords.latitude, lng: position.coords.longitude});
            },
            (error) => console.warn(error.message),
            { maximumAge:20000, timeout:10000, enableHighAccuracy: true }
        );
    }

    */

    useEffect(() => {

        const watchId = navigator.geolocation.watchPosition(
            (position) => {
            setUserCooridinates({
                lat: position.coords.latitude,
                lng: position.coords.longitude
                });
            },
            (error) => {
                console.warn(error.message);
            },
            { maximumAge: 10000, timeout: 8000, enableHighAccuracy: true }
        );
        return () => {
            navigator.geolocation.clearWatch(watchId);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        
    }, []);

    useEffect(() => {

        let current = permissionsState.location;
        let now = prevPermissionsState.now;

        if(current !== now){

            setPrevPermissionsState({
                now: current,
                past: now
            })

        }

    }, [permissionsState, prevPermissionsState.now]);

    useEffect(() => {

        //getLocation();

        function checkPermission() {
            navigator.permissions.query({ name: "geolocation" }).then((result) => {
                setPermissionsState({
                    location: result.state
                });
            });
        }
        
        checkPermission();
        
        setInterval(checkPermission, 2500);

    }, []);

    // Przywracanie ostatniej pozycji

    useEffect(() => {
        db.last_state.toArray().then(function(result){
            if(result.length > 0){
                let obj = result[0];
                setActive({...active, job_number: obj.job_number, job_date: obj.job_date});
                if(Object.hasOwn(obj, 'train_id')){
                    setPreviousTrain(obj.train_id);
                } 
            }             
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --- Uzyskiwanie danych ---

    // Pobieranie danych

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData(par) {

        let personal_id;

        if(!par){
            personal_id = user.personal_id;
        } else {
            personal_id = '*';
        }

        setSearching(true);

        Axios.post('classes/data.php', { personal_id }, { timeout: 5000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){
                if(Array.isArray(response.data.jobs)){
                    setJobs(response.data.jobs);
                    setJobsOffline(response.data.jobs);
                }
                if(Array.isArray(response.data.rides)){
                    setRides(response.data.rides);
                    setRidesOffline(response.data.rides);
                }
                if(Array.isArray(response.data.trains)){
                    setTrains(response.data.trains);
                    setTrainsOffline(response.data.trains);
                }
                if(Array.isArray(response.data.stations)){
                    setStations(response.data.stations);
                    setStationsOffline(response.data.stations);
                }
                if(Array.isArray(response.data.train_numbers)){
                    setTrainNumbers(response.data.train_numbers);
                    setTrainNumbersOffline(response.data.train_numbers);
                }
                setSearching(false);
            } else if (response.data === 0) {
                setJobs([]);
                setJobsOffline([]);
                setRides([]);
                setRidesOffline([]);
                setTrains([]);
                setTrainsOffline([]);
                setStations([]);
                setStationsOffline([]);
                setTrainNumbers([]);
                setTrainNumbersOffline([]);
                setSearching(false);
            } else {
                checkDataOffline();
            }
        }).catch((error) => {
            console.warn(error);
            checkDataOffline();
        });

    }

    // Sprawdzenie lokalnej bazy danych w przypadku braku połączenia z zewnętrzną bazą

    function checkDataOffline(){
        db.jobs.toArray().then(function(result){
            if(result.length > 0){
                setJobs(result);
            }
            setSearching(false); 
        });
        db.rides.toArray().then(function(result){
            if(result.length > 0){
                setRides(result);
            }
        });
        db.trains.toArray().then(function(result){
            if(result.length > 0){
                setTrains(result);
            }
        });
        db.stations.toArray().then(function(result){
            if(result.length > 0){
                setStations(result);
            }
        });
        db.train_numbers.toArray().then(function(result){
            if(result.length > 0){
                setTrainNumbers(result);
            }
        });
    }

    function setJobsOffline(data){
        db.jobs.clear();
        db.jobs.bulkPut(data)
    }

    function setRidesOffline(data){
        db.rides.clear();
        db.rides.bulkPut(data)
    }

    function setTrainsOffline(data){
        db.trains.clear();
        db.trains.bulkPut(data)
    }

    function setStationsOffline(data){
        db.stations.clear();
        db.stations.bulkPut(data)
    }

    function setTrainNumbersOffline(data){
        db.train_numbers.clear();
        db.train_numbers.bulkPut(data)
    }

    // Wyodrębnianie dat zadań

    useEffect(() => {
        if(jobs.length > 0){
            setJobsDates([...new Set(jobs.map(item => item.job_date))]);
            if(active.job_date && active.job_number){
                if(!jobs.some(obj => obj.job_date === active.job_date && obj.job_number === active.job_number)){
                    setActive({...active, job_number: '', job_date: ''});
                };
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs]);

    // Tworzenie listy unikalnych numerów zadań na podstawie wybranej daty

    useEffect(() => {
        if(active.job_date){
            setSeparatedJobs(jobs.filter(obj => {
                return obj.job_date === active.job_date
            }));
        }
    }, [active.job_date, jobs]);

    // Wyodrębnianie nazw zadań

    useEffect(() => {
        if(separatedJobs.length > 0){
            setJobsNames([...new Set(separatedJobs.map(item => item.job_number))]);
        }
    }, [separatedJobs]);

    // Ustawianie aktywnego zadania(daty i numeru)

    function setActiveSelection(name, value){
        if(name === 'job_number'){
            setActive({...active, job_number: value, train_id: '', train_number: '', total_cars: '', first_car: '', last_car: '', relation: '', start_hour: '', end_hour: '', status: ''});
            db.selected.toArray().then(function(result){
                if(result.length > 0){
                    db.selected.update(1, {'job_date': active.job_date, 'job_number': value});
                } else {
                    db.selected.put({'job_date': active.job_date, 'job_number': value});
                }
            });
            db.last_state.toArray().then(function(result){
                if(result.length > 0){
                    db.last_state.clear();
                }
                db.last_state.put({'index': 1, 'job_date': active.job_date, 'job_number': value});
            });
            setActiveRides([]);
            setActiveTrainIds([]);
            setActiveTrains([]);
            setMeasurementStations([]);
            setMeasurementFormData({});
            setCompletedMeasurements([]);
            setAppLayer(200);
        } else {
            setActive({...active, [name]: value});
        }
    }

    // Anulowanie zmiany zadania

    function cancelJobChange(){
        db.selected.toArray().then(function(result){
            let data = result[0];
            setActive({...active, 'cancel': '', 'job_date': data.job_date, 'job_number': data.job_number});
        });
    }

    // Wyodrębnianie wybranego aktywnego zadania

    useEffect(() => {
        if(active.job_number){
            setActiveJob(separatedJobs.filter(obj => {
                return obj.job_number === active.job_number
            }));
        }
    }, [active.job_number, separatedJobs]);

    // Wyodrębnianie kursów z aktywnego zadania

    useEffect(() => {
        if(active.job_number && activeJob.length > 0 && rides.length > 0){
            setActiveRides(rides.filter(obj => {
                return obj.job_number === active.job_number
            }));
        }
    }, [activeJob, rides, active.job_number]);

    // Wyodrębnianie id pociągów z aktywnych kursów i dodawanie danych odnośnie pociągów

    useEffect(() => {

        if(activeRides.length > 0 && trainNumbers.length > 0 && stations.length > 0){ 
            setActiveTrainIds([...new Set(activeRides.map(item => item.train_id))]);
            activeRides.forEach(ride => {
                const first_station_name = stations.find(u => u.station_id === ride.first_station_id);
                const last_station_name = stations.find(u => u.station_id === ride.last_station_id);
                const train_number = trainNumbers.find(u => u.train_id === ride.train_id);
                if(first_station_name){
                    ride.first_station_name = first_station_name.name;
                }
                if(last_station_name){
                    ride.last_station_name = last_station_name.name;
                }
                if(train_number){
                    ride.train_number = train_number.train_number;
                }
                const begin_station_name = stations.find(u => u.station_id === train_number.first_station);
                const end_station_name = stations.find(u => u.station_id === train_number.last_station);
                if(begin_station_name){
                    ride.begin_station = begin_station_name.name;
                }
                if(end_station_name){
                    ride.end_station = end_station_name.name;
                }
            });
        }
        
    }, [activeRides, stations, trainNumbers]);

    // Wyodrębnianie aktywnych pociągów

    useEffect(() => {
        if(activeTrainIds.length > 0 && trains.length > 0){
            setActiveTrains(trains.filter(train => activeTrainIds.includes(train.train_id)));
        }
    }, [activeTrainIds, trains]);

    // Dodanie informacji o pociągach

    useEffect(() => {
        if(activeTrains.length > 0 && stations.length > 0 && activeRides.length > 0){
            activeTrains.forEach(train => {
                const trainRide = activeRides.filter(ride => ride.train_id === train.train_id);
                let startStation = trainRide[0].first_station_id;
                let endStation = trainRide[0].last_station_id;
                const arr = [];
                for (let i = 1; i <= 60; i++) {
                    if(!train['station_'+i]){
                        break;
                    } else {
                        const small = [];
                        window['station_' + i + '_name'] = stations.find(u => u.station_id === train['station_'+i]);
                        train['station_' + i + '_name'] = window['station_' + i + '_name'].name;
                        small.push(train['station_' + i], window['station_' + i + '_name'].name, train['arrival_hour_'+i], train['departure_hour_'+i], train['platform_number_'+i], train['lane_number_'+i]);
                        arr.push(small);
                        if(train['station_'+i] === startStation){
                            train.measurement_start_hour = train['departure_hour_'+i];
                            train.start_station_index = i-1;
                        }
                        if(train['station_'+i] === endStation){
                            train.measurement_end_hour = train['arrival_hour_'+i];
                            train.end_station_index = i;
                        }
                    }
                }
                train.stations = arr;
            });
            if(previousTrain){
                activateTrain(previousTrain);
                setPreviousTrain(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTrains, stations, activeRides]);

    // Pokazywanie lub ukrywanie rozkładu jazdy pociągu

    function trainDetailsToggle(id){
        let el = document.querySelector("#train-details-inner-section-"+id);
        let button = document.querySelector("#train-details-show-button-"+id);
        if(el.classList.contains('train-is')){
            el.classList.remove('train-is');
            button.innerText = 'Ukryj';
        } else {
            el.classList.add('train-is');
            button.innerText = 'Pokaż';
            button.scrollIntoView();
        }
    }

    // Wybór pociągu do pomiarów

    function activateTrain(id){

        if(id !== active.train_id){
            setCompletedMeasurements([]);
            setMeasurementFormData({});
        }

        const chosenRide = activeRides.filter(ride => ride.train_id === id);
        const chosenTrain = activeTrains.filter(train => train.train_id === id);

        if(chosenRide.length > 0 && chosenTrain.length > 0){
            setActive({...active, train_id: chosenRide[0].train_id, train_number: chosenRide[0].train_number, total_cars: parseInt(chosenRide[0].total_cars), first_car: parseInt(chosenRide[0].first_car), last_car: parseInt(chosenRide[0].last_car), relation: chosenRide[0].begin_station + ' - ' + chosenRide[0].end_station, start_hour: chosenTrain[0].measurement_start_hour, end_hour: chosenTrain[0].measurement_end_hour, status: chosenRide[0].status, measured_from_start: chosenTrain[0].start_station_index === 0 ? true : false});
            db.last_state.update(1, {'train_id': chosenRide[0].train_id, 'train_number': chosenRide[0].train_number, 'total_cars': parseInt(chosenRide[0].total_cars), 'first_car': parseInt(chosenRide[0].first_car), 'last_car': parseInt(chosenRide[0].last_car), 'relation': chosenRide[0].begin_station + ' - ' + chosenRide[0].end_station, 'start_hour': chosenTrain[0].measurement_start_hour, 'end_hour': chosenTrain[0].measurement_end_hour, 'status': chosenRide[0].status, 'measured_from_start': chosenTrain[0].start_station_index === 0 ? true : false});
            setActiveLayer(300);
            setFill(undefined);
        }

    }

    useEffect(() => {

        if(active.train_id){

            setCompletedMeasurements([]);

            const chosenTrain = activeTrains.filter(train => train.train_id === active.train_id);

            let first = chosenTrain[0].start_station_index;
            let last = chosenTrain[0].end_station_index;

            const stations = chosenTrain[0].stations.slice(first, last);

            const arr = [];

            for(let i=0; i<stations.length; i++){
                if(i === 0){
                    arr.push(stations[i]);
                }
                if(i > 0){
                    const small = [];
                    small[0] = stations[i-1][0] + '-' + stations[i][0];
                    small[1] = stations[i-1][1] + ' - ' + stations[i][1];
                    small[2] = stations[i-1][3] + ' - ' + stations[i][2];
                    arr.push(small);
                    arr.push(stations[i]);
                }
            }

            setMeasurementStations(arr);

            checkMeasurementsDB();
            
        }

        function checkMeasurementsDB(){         

            db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id}).toArray().then(function(result){
                if(result.length > 0){
                    setMeasurementsFromDB(result);
                }
            });

        }
        
    }, [active.train_id, activeTrains, active.job_date, active.job_number]);

    // Wypełnianie pomiarów na podstawie zapisanych w lokalnej bazie danych

    const [measurementsFromDB, setMeasurementsFromDB] = useState([]);

    const [latestMeasurements, setLatestMeasurements] = useState([]);

    useEffect(() => {

        if(measurementsFromDB.length > 0){
              
            const result = measurementsFromDB.reduce((acc, obj) => {
                const { station_index, measurement_id } = obj;
              
                if (!acc[station_index] || acc[station_index].measurement_id < measurement_id) {
                  acc[station_index] = obj;
                }
              
                return acc;
            }, {});
              
            const finalArray = Object.values(result);

            let lastMeasurement = Math.max(...finalArray.map(a => a.station_index));

            const completedArray = [];

            for(let i = 0; i <= lastMeasurement; i++){
                completedArray.push(i);
            }

            setCompletedMeasurements(completedArray);
              
            setLatestMeasurements(finalArray);
        }
        
    }, [measurementsFromDB]);

    useEffect(() => {

        const result = latestMeasurements.map(a => a.measurements);

        const storage = [];

        result.forEach(item => {

            if(Object.keys(item).some(function(k){ return ~k.indexOf("user_camera_photo") })){

                Object.entries(item).forEach(([key, value], ix) => {
    
                    if(key.includes("user_camera_photo")){
    
                        const arr = key.split("-");
                        let index = arr[1];
    
                        const objectUrl = URL.createObjectURL(value);
    
                        const obj = {["user_camera_photo_url-"+index]: objectUrl}
    
                        storage.push(obj);
    
                        return () => URL.revokeObjectURL(objectUrl);
    
                    }
    
                });
    
            }

        });

        if(storage.length > 0){
            storage.forEach(item => {
                result.push(item);
            });
        }

        const allMeasurements = Object.assign({}, ...result);

        // Obliczenia zapełnienia dla kolejnego etapu

        if(completedMeasurements.length > 0){

            let maximum = Math.max(...completedMeasurements);

            // Jeśli ostatni pomiar był na stacji

            if(maximum % 2 === 0){

                // Jeśli to pierwsza stacja i początek trasy pociągu

                if(maximum === 0 && active.measured_from_start === true){

                    let current_station = measurementStations[0][0];

                    let next_station = measurementStations[1][0];

                    let entered = parseInt(allMeasurements[current_station+'-0-1']);

                    let exited = parseInt(allMeasurements[current_station+'-0-2']);

                    allMeasurements[next_station+'-fill-previous-1'] = entered - exited;

                    setFill(entered - exited);

                // Jeśli to nie jest pierwsza stacja

                } else if(maximum > 0 && maximum < (measurementStations.length -1)){

                    let current_station = measurementStations[maximum][0];

                    let next_station = measurementStations[maximum+1][0];

                    let entered = parseInt(allMeasurements[current_station+'-'+maximum+'-1']);

                    let exited = parseInt(allMeasurements[current_station+'-'+maximum+'-2']);

                    if(allMeasurements[current_station+'-fill-calculated-'+(maximum)] && allMeasurements[current_station+'-fill-calculated-'+(maximum)] !== '-'){

                        let previous_sum = parseInt(allMeasurements[current_station+'-fill-calculated-'+(maximum)]);

                        allMeasurements[next_station+'-fill-previous-'+(maximum+1)] = previous_sum;

                        setFill(previous_sum);

                    } else if(allMeasurements[current_station+'-fill-previous-'+(maximum)] && allMeasurements[current_station+'-fill-previous-'+(maximum)] !== '-'){

                        let previous_sum = parseInt(allMeasurements[current_station+'-fill-previous-'+(maximum-1)]);

                        allMeasurements[next_station+'-fill-previous-'+(maximum+1)] = previous_sum + entered - exited;

                        setFill(previous_sum);

                    }

                } 

            // Jeśli ostatni pomiar był w wagonach

            } else {

                let current_station = measurementStations[maximum][0];

                let next_station = measurementStations[maximum+1][0];

                if(allMeasurements[current_station+'-fill-calculated-'+(maximum)] && allMeasurements[current_station+'-fill-calculated-'+(maximum)] !== '-'){

                    let previous_sum = parseInt(allMeasurements[current_station+'-fill-calculated-'+(maximum)]);

                    allMeasurements[next_station+'-fill-previous-'+(maximum+1)] = previous_sum;

                    setFill(previous_sum);

                } else if(allMeasurements[current_station+'-fill-previous-'+(maximum)] && allMeasurements[current_station+'-fill-previous-'+(maximum)] !== '-'){

                    let previous_sum = parseInt(allMeasurements[current_station+'-fill-previous-'+(maximum)]);

                    allMeasurements[next_station+'-fill-previous-'+(maximum+1)] = previous_sum;

                    setFill(previous_sum);

                }            

            }

        }

        setMeasurementFormData(allMeasurements);

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [latestMeasurements]);
    
    // Manualna zmiana wartości pól pomiarowych

    function measurementFormChange(event, index, car){

        const {name, value} = event.target;

        let mod = index%2;

        // Zaznaczenie pomiaru szacunkowego

        if(event.target.type === 'checkbox'){

            let val;

            if(event.target.checked === true){
                val = true;
            } else {
                val = false;
            }

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [name]: val
                }
            });  

        } else if(event.target.type === 'textarea') {
            
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [name]: value
                }
            });  
            
        // Pola pomiaru osób

        } else {

            let current_station = measurementStations[index][0];

            // Pomiary nieparzyste - przejazdy

            if(mod !== 0){

                let cars = active.last_car - active.first_car + 1;

                let sum = 0;

                let not_measured = false;

                // Sumowanie wartości z wagonów

                for(let i = 0;i < cars;i++){

                    if(i === car){
                        continue;
                    }

                    let car_value = parseInt(measurementFormData[current_station+'-'+index+'-car-'+(active.first_car+i)]);

                    if(car_value === -1){
                        not_measured = true;
                    }

                    if(isNaN(car_value) || car_value < 0){
                        car_value = 0;
                    }

                    sum += parseInt(car_value);

                }

                let num_val = parseInt(value);

                if(num_val > 0){
                    sum += num_val;
                }                

                // Obliczanie różnicy zapełnienia

                let difference;

                if(measurementFormData[current_station+'-fill-previous-'+index] && not_measured === false){
                    difference = Math.abs(sum - parseInt(measurementFormData[current_station+'-fill-previous-'+index]));
                } else {
                    difference = '-';
                }

                if(not_measured === true){
                    sum = '-';
                }

                // Jeśli wpisana wartość jest mniejsza niż 0(pomijanie wagonu)

                if(num_val < 0){

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: '-1'
                        }
                    });

                } else {

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: value
                        }
                    });

                }

            // Pomiary parzyste - stacje    

            } else {

                let sum;

                let difference;

                let new_value;

                let entered;

                let exited;

                if(parseInt(value) >= 0){
                    new_value = value;
                } else {
                    new_value = '0';
                }

                if(measurementFormData[current_station+'-fill-previous-'+index]){

                    let previous_fill = parseInt(measurementFormData[current_station+'-fill-previous-'+index]);

                    let entered_name = current_station+'-'+index+'-1';

                    let exited_name = current_station+'-'+index+'-2';

                    // Wpisywanie wsiadających

                    if(name === entered_name){

                        entered = parseInt(new_value);
                        
                        exited = measurementFormData[exited_name] ? parseInt(measurementFormData[exited_name]) : 0;

                    // Wpisywanie wysiadających

                    } else {

                        entered = measurementFormData[entered_name] ? parseInt(measurementFormData[entered_name]) : 0;

                        exited = parseInt(new_value);

                    }

                    sum = previous_fill + entered - exited;

                    difference = Math.abs(previous_fill - sum);

                    let maximum = Math.max(...completedMeasurements);

                    if(maximum < index){

                        setFill(sum);

                    }

                    //////////////////////////////////////////////////

                } else if(index === 0 && active.measured_from_start === true){

                    let entered_name = current_station+'-'+index+'-1';

                    let exited_name = current_station+'-'+index+'-2';

                    if(name === entered_name){

                        entered = parseInt(new_value);
                        
                        exited = measurementFormData[exited_name] ? parseInt(measurementFormData[exited_name]) : 0;

                    } else {

                        entered = measurementFormData[entered_name] ? parseInt(measurementFormData[entered_name]) : 0;

                        exited = parseInt(new_value);

                    }

                    sum = entered - exited;

                    difference = '-';

                //////////////////////////////////////////

                } else {

                    sum = '-';
                    difference = '-';

                }

                setMeasurementFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        [current_station+'-fill-calculated-'+index]: sum,
                        [current_station+'-fill-difference-'+index]: difference,
                        [name]: new_value
                    }
                }); 

            }

        }
        
    }


    // Przyciski zmniejszania/zwiększania ilości

    function changeMeasurementFormValue(rawName, type, index, car, kind){

        let name = rawName[0];

        let value = parseInt(measurementFormData[name]);

        let current_station = measurementStations[index][0];

        let difference;

        let sum = 0;

        let not_measured = false;

        // Obliczenia zapełnienia dla wagonów

        if(kind === 'car'){

            let cars = active.last_car - active.first_car + 1;

            for(let i = 0;i < cars;i++){

                if(i === car){
                    continue;
                }

                let car_value = parseInt(measurementFormData[current_station+'-'+index+'-car-'+(active.first_car+i)]);

                if(car_value === -1){
                    not_measured = true;
                }

                if(isNaN(car_value) || car_value < 0){
                    car_value = 0;
                }

                sum += car_value;

            }

        }

        // Odejmowanie

        if(type === 'decrement'){

            // Jeśli nie ma wartości lub jest zero bądź ujemna

            if(!value || value <= 0){

                // Jeśli to wagony

                if(kind === 'car'){

                    sum = '-';

                    difference = '-';

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: '-1'
                        }
                    });

                // Jeśli to stacja

                } else {

                    let sum;

                    let difference;

                    if(measurementFormData[current_station+'-fill-previous-'+index]){

                        let current_fill = parseInt(measurementFormData[current_station+'-fill-previous-'+index]);

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        sum = current_fill + current_entered - current_exited;
                        difference = Math.abs(current_fill - sum);

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                        /////////////////////////////////////////////////

                    } else if(index === 0 && active.measured_from_start === true){

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        sum = current_entered - current_exited;
                        difference = '-';

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                    } else {

                        sum = '-';
                        difference = '-';

                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: '0'
                        }
                    });

                }

            // Jeśli wartości są dodatnie

            } else {

                // Jeśli to wagony

                if(kind === 'car'){

                    if(not_measured === false){
                        sum += value -1;
                    } else {
                        sum = '-';
                    }

                    if(measurementFormData[current_station+'-fill-previous-'+index] && not_measured === false){
                        difference = Math.abs(sum - parseInt(measurementFormData[current_station+'-fill-previous-'+index]));
                    } else {
                        difference = '-';
                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: (value -1).toString()
                        }
                    });

                // Jeśli to stacje

                } else {

                    let sum;

                    let difference;

                    if(measurementFormData[current_station+'-fill-previous-'+index]){

                        let current_fill = parseInt(measurementFormData[current_station+'-fill-previous-'+index]);

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered--;
                        } else if(kind === 'exit'){
                            current_exited--;
                        }

                        sum = current_fill + current_entered - current_exited;
                        difference = Math.abs(current_fill - sum);

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                        ////////////////////////////////////////////

                    } else if(index === 0 && active.measured_from_start === true){

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered--;
                        } else if(kind === 'exit'){
                            current_exited--;
                        }

                        sum = current_entered - current_exited;
                        difference = '-';

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                    } else {

                        sum = '-';
                        difference = '-';

                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: (value -1).toString()
                        }
                    });

                }

            }

        // Dodawanie

        } else {

            // Jeśli nie ma wartości w polu

            if(!value){

                if(kind === 'car'){

                    if(not_measured === false){
                        sum += 1;
                    } else {
                        sum = '-';
                    }

                    if(measurementFormData[current_station+'-fill-previous-'+index] && not_measured === false){
                        difference = Math.abs(sum - parseInt(measurementFormData[current_station+'-fill-previous-'+index]));
                    } else {
                        difference = '-';
                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: '1'
                        }
                    });
            
                } else {

                    let sum;

                    let difference;

                    if(measurementFormData[current_station+'-fill-previous-'+index]){

                        let current_fill = parseInt(measurementFormData[current_station+'-fill-previous-'+index]);

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered++;
                        } else if(kind === 'exit'){
                            current_exited++;
                        }

                        sum = current_fill + current_entered - current_exited;
                        difference = Math.abs(current_fill - sum);

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                        ///////////////////////////////////////

                    } else if(index === 0 && active.measured_from_start === true){

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered++;
                        } else if(kind === 'exit'){
                            current_exited++;
                        }

                        sum = current_entered - current_exited;
                        difference = '-';

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                    } else {

                        sum = '-';
                        difference = '-';

                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: '1'
                        }
                    });

                    //setMeasurementFormData({...measurementFormData, [name]: '1'});

                }

            // Jeśli jest wartość w polu

            } else {

                // Jeśli to wagon

                if(kind === 'car'){

                    if(not_measured === false){
                        sum += value +1;
                    } else {
                        sum = '-';
                    }

                    if(measurementFormData[current_station+'-fill-previous-'+index] && not_measured === false){
                        difference = Math.abs(sum - parseInt(measurementFormData[current_station+'-fill-previous-'+index]));
                    } else {
                        difference = '-';
                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: (value +1).toString()
                        }
                    });

                // Jeśli to stacje

                } else {

                    let sum;

                    let difference;

                    if(measurementFormData[current_station+'-fill-previous-'+index]){

                        let current_fill = parseInt(measurementFormData[current_station+'-fill-previous-'+index]);

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered++;
                        } else if(kind === 'exit'){
                            current_exited++;
                        }

                        sum = current_fill + current_entered - current_exited;
                        difference = Math.abs(current_fill - sum);

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                        //////////////////////////////////////////

                    } else if(index === 0 && active.measured_from_start === true){

                        let current_entered;

                        let current_exited;

                        if(measurementFormData[current_station+'-'+index+'-1']){
                            current_entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);
                        } else {
                            current_entered = 0;
                        }

                        if(measurementFormData[current_station+'-'+index+'-2']){
                            current_exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);
                        } else {
                            current_exited = 0;
                        }

                        if(kind === 'enter'){
                            current_entered++;
                        } else if(kind === 'exit'){
                            current_exited++;
                        }

                        sum = current_entered - current_exited;
                        difference = '-';

                        let maximum = Math.max(...completedMeasurements);

                        if(maximum < index){

                            setFill(sum);
                            
                        }

                    } else {

                        sum = '-';
                        difference = '-';

                    }

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [current_station+'-fill-calculated-'+index]: sum,
                            [current_station+'-fill-difference-'+index]: difference,
                            [name]: (value +1).toString()
                        }
                    });

                }

            }

        }

    }

    // Pomijanie aktualnego pomiaru

    const [toSkip, setToSkip] = useState({rawName: '', index: ''});

    const [selectedOption, setSelectedOption] = useState('za dużo osób');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    function skipMeasurement(rawName, index){

        setToSkip({rawName: rawName, index: index});

        let ele = document.querySelector('#modal-2-description');

        let prev = index - 1;

        let previous_station = measurementStations[prev][0];

        let entered = parseInt(measurementFormData[previous_station+'-'+prev+'-1']);

        let exited = parseInt(measurementFormData[previous_station+'-'+prev+'-2']);

        if(!measurementFormData[rawName+'-fill-previous-'+index] || measurementFormData[rawName+'-fill-previous-'+index] === '-'){

            ele.innerText = 'Pomiar zapełnienia powinien być teraz przeprowadzony ponieważ nie był wykonany wcześniej. Jeśli nie możesz go wykonać proszę wybierz powód z poniższej listy.';
            showModal(2);

        } else if((entered + exited) > 30){

            ele.innerText = 'Pomiar zapełnienia powinien być teraz przeprowadzony ponieważ na poprzedniej stacji nastąpiła wymiana więcej niż 30 pasażerów. Jeśli nie możesz go wykonać proszę wybierz powód z poniższej listy.';
            showModal(2);

        } else if(index === (measurementStations.length -2)){

            ele.innerText = 'Pomiar zapełnienia powinien być teraz przeprowadzony ponieważ zbliżasz się do ostatniej mierzonej stacji. Jeśli nie możesz go wykonać proszę wybierz powód z poniższej listy.';
            showModal(2);
            
        } else {

            acceptMeasurementForm(rawName, index, true);

        }

    }

    function sendSkip(){

        closeModal(2);

        let rawName = toSkip.rawName;

        let index = toSkip.index;

        acceptMeasurementForm(rawName, index, true);

    }

    const [recalculated, setRecalculated] = useState({});



    // Sprawdzanie aktualnego pomiaru

    function acceptMeasurementForm(rawName, index, skip){

        //getLocation();

        let err = document.querySelector(["#train-measurements-form-error-"+index]);

        let currentDate = new Date();
        
        let currentDateOffset = currentDate.getTimezoneOffset(); 
        
        currentDate = new Date(currentDate.getTime() + (currentDateOffset*60*1000)); 
        
        let currentDay = currentDate.toISOString().split('T')[0];

        const localDate = new Date();

        let currentTime = localDate.toLocaleTimeString('pl-PL');

        const measurements = {}

        if(Object.hasOwn(measurementFormData, [rawName+"-"+index+'-checkbox'])){
            measurements[rawName+"-"+index+'-checkbox'] = measurementFormData[rawName+"-"+index+'-checkbox'];
        }

        // Stacje

        if(index % 2 === 0){
            let ele1 = document.querySelector(['#'+rawName+'-'+index+'-1']);
            let ele2 = document.querySelector(['#'+rawName+'-'+index+'-2']);
            let val1 = measurementFormData[rawName+'-'+index+'-1'];
            let val2 = measurementFormData[rawName+'-'+index+'-2'];
            measurements[rawName+'-'+index+'-1'] = measurementFormData[rawName+'-'+index+'-1'];
            measurements[rawName+'-'+index+'-2'] = measurementFormData[rawName+'-'+index+'-2'];
            measurements[rawName+'-fill-previous-'+index] = measurementFormData[rawName+'-fill-previous-'+index];
            measurements[rawName+'-fill-calculated-'+index] = measurementFormData[rawName+'-fill-calculated-'+index];
            measurements[rawName+'-fill-difference-'+index] = measurementFormData[rawName+'-fill-difference-'+index];
            if(!val1 || !val2){
                err.innerText = 'Proszę uzupełnij brakujące wartości';
                if(!val1){
                    ele1.classList.add('form-field-error');
                }
                if(!val2){
                    ele2.classList.add('form-field-error');
                }
            } else {
                ele1.classList.remove('form-field-error');
                ele2.classList.remove('form-field-error');
                err.innerText = '';

                /*
                if(!(completedMeasurements.includes(index))){
                    setNextStation(index);
                } else {
                    let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
                    wrapper.style.display = "none";
                }

                */

                reCalculateFill();
                setNextStation(index);
                setCompletedMeasurements(prev => [...prev,index]);
                completeMeasurements();
                
            }

        // Przejazdy między stacjami

        } else {
            let cars_numbers = active.last_car - active.first_car +1;
            const arr = [];
            for(let i = 0; i < cars_numbers; i++){
                window['ele-'+i] = document.querySelector(['#'+rawName+'-'+index+'-car-'+(active.first_car+i)]);
                window['val-'+i]= measurementFormData[rawName+'-'+index+'-car-'+(active.first_car+i)];
                if(!window['val-'+i]){
                    window['ele-'+i].classList.add('form-field-error');
                } else {
                    window['ele-'+i].classList.remove('form-field-error');
                }
                arr.push(window['val-'+i]);
                measurements[rawName+'-'+index+'-car-'+(active.first_car+i)] = measurementFormData[rawName+'-'+index+'-car-'+(active.first_car+i)];
            }
            measurements[rawName+'-fill-previous-'+index] = measurementFormData[rawName+'-fill-previous-'+index];
            
            if(skip === true){

                setMeasurementFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        [rawName+'-fill-calculated-'+index]: '-',
                        [rawName+'-fill-difference-'+index]: '-'
                    }
                });

                measurements[rawName+'-fill-calculated-'+index] = '-';
                measurements[rawName+'-fill-difference-'+index] = '-';

                if(toSkip.rawName === rawName && toSkip.index === index){
                    measurements.skip_reason = selectedOption;
                } else {
                    measurements.skip_reason = 'pomiar niewymagany';
                }

            } else {

                measurements[rawName+'-fill-calculated-'+index] = measurementFormData[rawName+'-fill-calculated-'+index];
                measurements[rawName+'-fill-difference-'+index] = measurementFormData[rawName+'-fill-difference-'+index];

            }

            if(skip === false && arr.includes(undefined)){
                err.innerText = 'Proszę uzupełnij brakujące wartości';
            } else {
                err.innerText = '';

                /*

                if(!(completedMeasurements.includes(index))){
                    setNextStation(index);
                } else {
                    let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
                    wrapper.style.display = "none";
                }

                */

                reCalculateFill();
                setCompletedMeasurements(prev => [...prev,index]);
                setNextStation(index);
                completeMeasurements();
                
            }
            
        }

        function reCalculateFill(){

            let max = Math.max(...completedMeasurements);

            // Jeśli nastąpiło cofnięcie się do wcześniej zmierzonego etapu i ponowny pomiar oraz są wartości zapełnienia

            if(index < max && ((measurementFormData[rawName+'-fill-calculated-'+index] && measurementFormData[rawName+'-fill-calculated-'+index] !== '-') || (index === 0 && active.measured_from_start === true))){

                //console.log('rozpoczynam funkcje');

                // Wyznaczanie różnicy między etapami
                
                let difference = max - index +1;

                // Dla każdego kolejnego etapu między mierzonym, a aktualnym

                let temp_fill_previous;

                for(let i=1;i<=difference;i++){
                    
                    // Aktualny indeks
                    
                    let indeks = index + i;

                    //console.log('poczatek petli nr '+i);

                    //console.log('indeks '+indeks);

                    // Aktualny etap

                    let station

                    if(measurementStations[indeks]){

                        station = measurementStations[indeks][0];

                    } else {

                        break

                    }

                    /*

                    // Poprzedni indeks

                    let prev = indeks -1;

                    // Poprzedni etap

                    let previous_station = measurementStations[prev][0];

                    // Następny indeks

                    let next_indeks = indeks + 1;

                    // Następny etap
                       
                    let next_station = measurementStations[next_indeks][0];

                    */

                    // Pierwsza pętla

                    if(i === 1){

                        //console.log('pierwsza pętla');

                        // Stacja

                        if(indeks%2 === 0){

                            //console.log('przeliczanie stacji');

                            let ent = parseInt(measurementFormData[station+'-'+indeks+'-1']);

                            let ext = parseInt(measurementFormData[station+'-'+indeks+'-2']);

                            let fill_prev = measurementFormData[rawName+'-fill-calculated-'+index];

                            let fill_calc = fill_prev + ent - ext;

                            setRecalculated(prevFormData => {
                                return {
                                    ...prevFormData,
                                    [station+'-fill-previous-'+indeks]: fill_prev,
                                    [station+'-fill-calculated-'+indeks]: fill_calc,
                                }
                            });

                            db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                if(result.length > 0){
                                    let last = result.length -1;
                                    const measurement_object = result[last];
                                    let measurement_index = measurement_object.index;
                                    const old_measurements = measurement_object.measurements;
                                    old_measurements[station+'-fill-previous-'+indeks] = fill_prev;
                                    old_measurements[station+'-fill-calculated-'+indeks] = fill_calc;
                                    db.measurements.update(measurement_index, {
                                        measurements: old_measurements
                                    });
                                }
                            });

                            temp_fill_previous = fill_calc;

                        // Przejazd

                        } else {

                            let fill_prev = measurementFormData[rawName+'-fill-calculated-'+index];

                            // Sprawdzamy czy jest wyznaczone zapełnienie i jeśli jest obliczamy różnicę i kończymy pętle

                            if(measurementFormData[station+'-fill-calculated-'+indeks] && measurementFormData[station+'-fill-calculated-'+indeks] !== '-'){

                                //console.log('wyznaczam różnicę i kończę pętle');

                                let fill_diff = Math.abs(fill_prev - measurementFormData[station+'-fill-calculated-'+indeks]);

                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev,
                                        [station+'-fill-difference-'+indeks]: fill_diff
                                    }
                                });

                                db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                    if(result.length > 0){
                                        let last = result.length -1;
                                        const measurement_object = result[last];
                                        let measurement_index = measurement_object.index;
                                        const old_measurements = measurement_object.measurements;
                                        old_measurements[station+'-fill-previous-'+indeks] = fill_prev;
                                        old_measurements[station+'-fill-difference-'+indeks] = fill_diff
                                        db.measurements.update(measurement_index, {
                                            measurements: old_measurements
                                        });
                                    }
                                });

                                break;

                            } else {

                                //console.log('wpisuję zapełnienie poprzednie i kontynuuję pętlę');

                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev
                                    }
                                });

                                db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                    if(result.length > 0){
                                        let last = result.length -1;
                                        const measurement_object = result[last];
                                        let measurement_index = measurement_object.index;
                                        const old_measurements = measurement_object.measurements;
                                        old_measurements[station+'-fill-previous-'+indeks] = fill_prev
                                        db.measurements.update(measurement_index, {
                                            measurements: old_measurements
                                        });
                                    }
                                });

                                temp_fill_previous = fill_prev;

                            }

                        }

                    // Kolejne pętle

                    } else {

                        // Stacja

                        if(indeks%2 === 0){

                            //console.log('przeliczanie stacji');

                            // Jeśli to nie jest aktualnie mierzony etap

                            if(indeks <= max){

                                let ent = parseInt(measurementFormData[station+'-'+indeks+'-1']);

                                let ext = parseInt(measurementFormData[station+'-'+indeks+'-2']);
    
                                let fill_prev = temp_fill_previous;
    
                                let fill_calc = fill_prev + ent - ext;
    
                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev,
                                        [station+'-fill-calculated-'+indeks]: fill_calc,
                                    }
                                });
    
                                temp_fill_previous = fill_calc;

                                // Aktualizowanie wpisów w lokalnej bazie danych

                                db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                    if(result.length > 0){
                                        let last = result.length -1;
                                        const measurement_object = result[last];
                                        let measurement_index = measurement_object.index;
                                        const old_measurements = measurement_object.measurements;
                                        old_measurements[station+'-fill-previous-'+indeks] = fill_prev;
                                        old_measurements[station+'-fill-calculated-'+indeks] = fill_calc;
                                        db.measurements.update(measurement_index, {
                                            measurements: old_measurements
                                        });
                                    }
                                });

                            } else {

                                let ent;

                                let ext;

                                if(measurementFormData[station+'-'+indeks+'-1']){
                                    ent = parseInt(measurementFormData[station+'-'+indeks+'-1']);
                                } else {
                                    ent = 0;
                                }

                                if(measurementFormData[station+'-'+indeks+'-2']){
                                    ext = parseInt(measurementFormData[station+'-'+indeks+'-2']);
                                } else {
                                    ext = 0;
                                }

                                let fill_prev = temp_fill_previous;
    
                                let fill_calc = fill_prev + ent - ext;
    
                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev,
                                        [station+'-fill-calculated-'+indeks]: fill_calc
                                    }
                                });

                                setFill(fill_calc);

                            }

                        // Przejazd

                        } else {

                            let fill_prev = temp_fill_previous;

                            // Sprawdzamy czy jest wyznaczone zapełnienie i jeśli jest obliczamy różnicę i kończymy pętle

                            if(measurementFormData[station+'-fill-calculated-'+indeks] && measurementFormData[station+'-fill-calculated-'+indeks] !== '-'){

                                //console.log('wyznaczam różnicę i kończę pętle');

                                let fill_diff = Math.abs(fill_prev - measurementFormData[station+'-fill-calculated-'+indeks]);

                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev,
                                        [station+'-fill-difference-'+indeks]: fill_diff
                                    }
                                });

                                db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                    if(result.length > 0){
                                        let last = result.length -1;
                                        const measurement_object = result[last];
                                        let measurement_index = measurement_object.index;
                                        const old_measurements = measurement_object.measurements;
                                        old_measurements[station+'-fill-previous-'+indeks] = fill_prev;
                                        old_measurements[station+'-fill-difference-'+indeks] = fill_diff;
                                        db.measurements.update(measurement_index, {
                                            measurements: old_measurements
                                        });
                                    }
                                });

                                break;

                            } else {

                                //console.log('wpisuję zapełnienie poprzednie i kontynuuję pętlę');

                                setRecalculated(prevFormData => {
                                    return {
                                        ...prevFormData,
                                        [station+'-fill-previous-'+indeks]: fill_prev
                                    }
                                });

                                db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: indeks}).toArray().then(function(result){
                                    if(result.length > 0){
                                        let last = result.length -1;
                                        const measurement_object = result[last];
                                        let measurement_index = measurement_object.index;
                                        const old_measurements = measurement_object.measurements;
                                        old_measurements[station+'-fill-previous-'+indeks] = fill_prev;
                                        db.measurements.update(measurement_index, {
                                            measurements: old_measurements
                                        });
                                    }
                                });

                                if(indeks > max){

                                    setFill(fill_prev);
    
                                }

                            }

                        }

                    }
                    
                }

            // Jeśli to ostatni etap(najnowszy pomiar)    

            } else {

                //console.log('nie rozpoczynam funkcji');

                return;

            }

        }

        // Zachowywanie pomiarów w lokalnej bazie danych

        function completeMeasurements(){

            let maximum = Math.max(...completedMeasurements);

            // Obliczanie zapełnienia

            // Pomiary parzyste - stacje

            if(index % 2 === 0){
                
                // Sprawdzenie czy nie jest to pierwsza stacja oraz jeśli pierwsza stacja to czy jest to też początek biegu pociągu

                let current_station = measurementStations[index][0];

                let entered = parseInt(measurementFormData[current_station+'-'+index+'-1']);

                let exited = parseInt(measurementFormData[current_station+'-'+index+'-2']);

                let next = index + 1;

                let next_station;

                if(next !== measurementStations.length){
                    next_station = measurementStations[next][0];
                }

                if(index > 0 && index !== measurementStations.length -1){

                    // Pobieranie wpisanej ilości ludzi w wagonach z poprzedniego przejazdu

                    if(measurementFormData[current_station+'-fill-calculated-'+index] && measurementFormData[current_station+'-fill-calculated-'+index] !== '-'){

                        let fill_calculated = parseInt(measurementFormData[current_station+'-fill-calculated-'+index]);
  
                        setMeasurementFormData(prevFormData => {
                            return {
                                ...prevFormData,
                                [next_station+'-fill-previous-'+next]: fill_calculated
                            }
                        });

                        if(maximum <= index){

                            setFill(fill_calculated);
                            
                        }

                    } else if(measurementFormData[current_station+'-fill-previous-'+index] && measurementFormData[current_station+'-fill-previous-'+index] !== '-'){

                        setMeasurementFormData(prevFormData => {
                            return {
                                ...prevFormData,
                                [next_station+'-fill-previous-'+next]: parseInt(measurementFormData[current_station+'-fill-previous-'+index])
                            }
                        });

                        if(maximum <= index){

                            setFill(measurementFormData[current_station+'-fill-previous-'+index]);
                            
                        }

                    } 

                } else if(index === 0 && active.measured_from_start === true){

                    // Stacja początkowa - tylko wsiadający

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            //[current_station+'-fill-calculated-'+index]: entered - exited,
                            [next_station+'-fill-previous-'+next]: entered - exited
                        }
                    });

                    if(maximum <= index){

                        setFill(entered - exited);
                        
                    }

                } else if(index === (measurementStations.length -1)){

                    db.last_state.toArray().then(function(result){
                        if(result.length > 0){
                            let obj = result[0];
                            db.last_state.clear();
                            db.last_state.put({'index': 1, 'job_date': obj.job_date, 'job_number': obj.job_number});
                        }
                    });

                }

            // Pomiary nieparzyste - przejazdy

            } else {

                let current_station = measurementStations[index][0];

                let next = index + 1;

                let next_station = measurementStations[next][0];

                // Sprawdzenie czy mamy wartość zapełnienia

                if(measurementFormData[current_station+'-fill-calculated-'+index] && measurementFormData[current_station+'-fill-calculated-'+index] !== '-' && !measurements.hasOwnProperty('skip_reason')){

                    let fill_calculated = parseInt(measurementFormData[current_station+'-fill-calculated-'+index]);

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [next_station+'-fill-previous-'+next]: fill_calculated
                        }
                    });

                    if(maximum <= index){

                        setFill(fill_calculated);
                        
                    }

                // Sprawdzenie czy mamy zapełnienie z poprzednich pomiarów

                } else if(measurementFormData[current_station+'-fill-previous-'+index] && measurementFormData[current_station+'-fill-previous-'+index] !== '-'){

                    setMeasurementFormData(prevFormData => {
                        return {
                            ...prevFormData,
                            [next_station+'-fill-previous-'+next]: parseInt(measurementFormData[current_station+'-fill-previous-'+index])
                        }
                    });

                    if(maximum <= index){

                        setFill(measurementFormData[current_station+'-fill-previous-'+index]);
                        
                    }

                }

            }

            const measurementsForDB = {
                personal_id: user.personal_id,
                job_date: active.job_date,
                job_number: active.job_number,
                train_id: active.train_id,
                train_number: active.train_number,
                station_index: index,
                date: currentDay,
                time: currentTime,
                gps_lat: userCoordinates.lat,
                gps_lng: userCoordinates.lng,
                first_car: active.first_car,
                last_car: active.last_car
            };

            if(index === 0 && active.status === "nierozpoczęty"){

                let obj = activeRides.find(f=>f.train_id === active.train_id);
                if(obj){
                    obj.status = "rozpoczęty";
                    measurements.status_update = 'rozpoczęty';
                }
                
            } else if(index === measurementStations.length -1){

                let obj = activeRides.find(f=>f.train_id === active.train_id);
                if(obj){
                    obj.status = "zakończony";
                    measurements.status_update = 'zakończony';
                }

            }

            measurements.name = active.job_number+"-"+active.train_id+"-"+rawName+"-"+index;

            const data = new FormData();

            if(measurementFormData["user_camera_photo-"+index]){
                data.append([measurements.name+"-single-"+index], measurementFormData["user_camera_photo-"+index]);
                measurements["user_camera_photo-"+index] = measurementFormData["user_camera_photo-"+index];
            }

            if(measurementFormData["user_included_photos-"+index]){
                measurementFormData["user_included_photos-"+index].forEach((file, index )=> data.append([measurements.name+"-"+index], file));
                measurements["user_included_photos-"+index] = measurementFormData["user_included_photos-"+index];
            }

            if(measurementFormData["additional-comments-active-"+index] === true && measurementFormData[rawName+"-additional-comments-"+index]){
                measurements[rawName+"-additional-comments-"+index] = measurementFormData[rawName+"-additional-comments-"+index];
                measurements["additional-comments-active-"+index] = true;
            }

            measurements.index = index;

            measurementsForDB.measurements = measurements;

            db.measurements.where({job_date: active.job_date, job_number: active.job_number, train_id: active.train_id, station_index: index}).toArray().then(function(result){
                if(result.length === 0){
                    measurementsForDB.measurement_id = 1;
                } else {
                    let last = result.length -1;
                    let last_measurement = result[last].measurement_id;
                    measurementsForDB.measurement_id = last_measurement + 1;
                }

                let json = JSON.stringify(measurementsForDB);

                data.append('data', json);

                Axios.post('classes/measurements.php', data, { timeout: 30000 }).then(function(response){
                    if(Array.isArray(response.data) && response.data[0] === true){
                        measurementsForDB.saved_in_DB = 'yes';
                    } else {
                        measurementsForDB.saved_in_DB = 'no';
                    }
                    db.measurements.put(measurementsForDB);
                }).catch((error) => {
                    console.warn(error);
                    measurementsForDB.saved_in_DB = "no";
                    db.measurements.put(measurementsForDB);
                });
        
            });

        }
        
    }

    useEffect(() => {

        if(Object.keys(recalculated).length > 0){
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ...recalculated
                }
            });
        }
        
    }, [recalculated]);

    useEffect(() => {

        if(Object.keys(recalculated).length > 0){
            setRecalculated({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurementFormData]);

    // Zapisywanie pomiarów z lokalnej bazy danych do centralnej

    function saveMeasurements(old_data){

        const data = new FormData();

        const measurements = old_data.map(a => a.measurements);

        let i = 0;

        measurements.forEach(measurement => {
            let index = measurement.index;
            let name = measurement.name;
            if(measurement.hasOwnProperty(['user_camera_photo-'+index])){
                data.append([name+"-"+i], measurement["user_camera_photo-"+index]);
                i++;
            }
            if(measurement.hasOwnProperty(['user_included_photos-'+index])){
                measurement['user_included_photos-'+index].forEach(image => {
                    data.append([name+"-"+i], image);
                    i++;
                });
            }
        });

        let json = JSON.stringify(old_data);

        data.append('data', json);

        Axios.post('classes/measurements.php', data , { timeout: 30000 }).then(function(response){
            if(Array.isArray(response.data)){
                let indexes = response.data;
                if(indexes.length > 0){
                    db.measurements.where('index').anyOf(indexes).modify({saved_in_DB: "yes"});
                }
            } 
        }).catch((error) => {
            console.warn(error);
        });

    }

    useEffect(() => {

        const h = setInterval(() => {
            checkSaveInDB();
        }, 60000)
        return () => { clearInterval(h) }

        function checkSaveInDB(){
            db.measurements.where('saved_in_DB').equals('no').toArray().then(function(result){
                if(result.length > 0){
                    saveMeasurements(result);
                }
            });
        }

    }, []);

    // Przesunięcie na następną stację

    function setNextStation(index){

        let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
        let last = measurementStations.length -1;

        if(index === last){
            setActiveLayer(200);
        } else{
            let index_plus = index + 1;
            let next = document.querySelector(["#train-measurements-wrapper-"+index_plus]);
            wrapper.style.display = "none";
            next.style.display = 'block';
        }
        
    }

    // Ręczna zmiana mierzonej stacji

    function changeCurrentStation(type, index){
        let wrapper = document.querySelector(["#train-measurements-wrapper-"+index]);
        let last = measurementStations.length -1;
        if(type === 'minus'){
            if(index === 0){
                return;
            } else {
                let index_minus = index - 1;
                let previous = document.querySelector(["#train-measurements-wrapper-"+index_minus]);
                wrapper.style.display = "none";
                previous.style.display = 'block';
            }
        }
        if(type === 'plus'){
            if(index === last){
                return;
            } else {
                if(completedMeasurements.includes(index)){
                    let index_plus = index + 1;
                    let next = document.querySelector(["#train-measurements-wrapper-"+index_plus]);
                    wrapper.style.display = "none";
                    next.style.display = 'block';
                } else {
                    return;
                }
            }
        }
    }

    // Przywracanie ostatniego pomiaru 

    useEffect(() => {
        if(appLayer === 300 && completedMeasurements.length > 0 && measurementStations.length > 0){
            let max = Math.max(...completedMeasurements);
            let max_total = measurementStations.length -1;
            if(max !== max_total){
                let wrapper = document.querySelector(["#train-measurements-wrapper-0"]);
                let next = document.querySelector(["#train-measurements-wrapper-"+(max+1)]);
                if(wrapper && next){
                    wrapper.style.display = "none";
                    next.style.display = 'block';
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLayer, completedMeasurements]);

    /*

    Przywrócić jeśli będą problemy z przywracaniem pomiarów

    const prevcompletedMeasurements = useRef([]);

    useEffect(() => {
        const wasEmpty = prevcompletedMeasurements.current.length === 0;
        const isEmptyNow = completedMeasurements.length === 0;
        if (wasEmpty && !isEmptyNow) {
            restoreMeasurements()
        }
        prevcompletedMeasurements.current = completedMeasurements;
    }, [completedMeasurements]);

    useEffect(() => {
        if(appLayer === 300 && completedMeasurements.length > 0){
            restoreMeasurements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLayer, completedMeasurements]);

    function restoreMeasurements(){
        let max = Math.max(...completedMeasurements);
        let max_total = measurementStations.length -1;
        if(max !== max_total){
            let wrapper = document.querySelector(["#train-measurements-wrapper-0"]);
            let next = document.querySelector(["#train-measurements-wrapper-"+(max+1)]);
            wrapper.style.display = "none";
            next.style.display = 'block';
        }
    }

    */

    // Dodawanie zdjęcia z telefonu

    function handlePhoto(index, e){

        if (!e.target.files || e.target.files.length === 0) {

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: undefined,
                    ["user_camera_photo_url-"+index]: undefined
                }
            });

            return

        }

        let type_raw = e.target.files[0].type;
        let type = type_raw.slice(0, 5);

        if(type !== 'image'){

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: undefined,
                    ["user_camera_photo_url-"+index]: undefined
                }
            });

            return;

        } else {

            const objectUrl = URL.createObjectURL(e.target.files[0]);

            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_camera_photo-"+index]: e.target.files[0],
                    ["user_camera_photo_url-"+index]: objectUrl
                }
            });
        
            return () => URL.revokeObjectURL(objectUrl);

        }

    }

    function removeCameraImage(index){
        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_camera_photo-"+index]: undefined,
                ["user_camera_photo_url-"+index]: undefined
            }
        });
    }

    // Wysyłanie wielu zdjęć

    function handlePhotos(index, e){

        if (!e.target.files || e.target.files.length === 0) {
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["user_included_photos-"+index]: undefined
                }
            });
            return
        }

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        const chosenImages = chosenFiles.filter(file => {
            return file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif"
        });

        let imagesToPut;

        if(Array.isArray(measurementFormData["user_included_photos-"+index])){
            const previousImages = measurementFormData["user_included_photos-"+index];
            imagesToPut = previousImages.concat(chosenImages);
        } else {
            imagesToPut = chosenImages;
        }

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_included_photos-"+index]: imagesToPut
            }
        });

    }

    function showPreviewPhoto(index, ix){

        const current = measurementFormData["user_included_photos-"+index][ix];

        const objectUrl = URL.createObjectURL(current);

        let imageElement = document.querySelector('#modal-preview-image');

        imageElement.src = objectUrl; 

        showModal('1');

    }

    function removeIncludedPhoto(index, ix){

        const current = measurementFormData["user_included_photos-"+index];

        const newArray = current.filter((item, index) => index !== ix);

        let imagesToPut;

        if(newArray.length > 0){
            imagesToPut = newArray;
        } else {
            imagesToPut = undefined;
        }

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                ["user_included_photos-"+index]: imagesToPut
            }
        });

    }

    // Modal z podglądem zdjęcia

    function showModal(ix){
        let ele = document.querySelector(['#modal-'+ix]);
        ele.classList.remove('modal-not-visible');
    }

    function closeModal(ix){
        let ele = document.querySelector(['#modal-'+ix]);
        ele.classList.add('modal-not-visible');
    }

    // Opcjonalny komentarz

    function toggleCommentBox(name, index){
        let current = measurementFormData["additional-comments-active-"+index];

        if(current === undefined || current === false){
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["additional-comments-active-"+index]: true
                }
            });
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            setMeasurementFormData(prevFormData => {
                return {
                    ...prevFormData,
                    ["additional-comments-active-"+index]: false,
                    [name+'-additional-comments-'+index]: ''
                }
            });
        }
    }

    function setActiveLayer(num){
        setAppLayer(num);
        window.scrollTo(0, 0);
    }

    function changeCheckbox(station, index){

        let name = station+'-'+index+'-checkbox';
        
        let ele = document.querySelector(['#'+name]);

        let current = ele.checked;

        let next = current === true ? false : true;

        ele.checked = next;
        
        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: next
            }
        });    
        
    }

    // Instrukcja

    const [activeInstruction, setActiveInstruction] = useState('');

    useEffect(() => {

        if(typeof activeInstruction === 'number'){

            let index = activeInstruction - 1;

            let element = document.getElementById(['instruction-menu-'+index]);
            
            element.scrollIntoView();

        }

    }, [activeInstruction]);

    return (
        <div id="app-outer-container">
            <div id="app-inner-container">
                {(permissionsState.location !== "denied" && permissionsState.location !== "granted" && prevPermissionsState.past !== 'granted') && 
                    <div className="waiting-wrapper">
                        <p className="waiting-message">Proszę czekać trwa pobieranie lokalizacji</p>
                        <Gear/>
                        <p className="location-waiting-message-add">Uwaga - lokalizacja urządzenia jest niezbędna do prawidłowego funkcjonowania aplikacji.</p>
                    </div>
                }
                {permissionsState.location === "denied" && 
                    <div className="waiting-wrapper">
                        <p className="waiting-message">Nie udało się pozyskać lokalizacji</p>
                        <img
                            src={Icon0}
                            alt='icon'
                            className="error-icon"
                        />
                        <p className="location-waiting-message-add">Lokalizacja jest niezbędna do prawidłowego działania aplikacji.</p>
                        <p className="location-waiting-message-add">Upewnij się, że aplikacja ma do niej uprawnienia.</p>
                    </div>
                }
                {(permissionsState.location === "granted" || (permissionsState.location === "prompt" && prevPermissionsState.past === 'granted')) && 
                <>
                    {jobs.length === 0 && !active.job_date &&
                        <div className="waiting-wrapper">
                            {searching && 
                            <>
                                <p className="waiting-message">Wyszukiwanie dostępnych zadań</p>
                                <Gear/>
                            </>}
                            {!searching && 
                            <>
                                <p className="waiting-message">Brak dostępnych zadań</p>
                                <button className={user.role === 'admin' ? "back-button back-button-extended" : "back-button"} onClick={()=>getData()}>Sprawdź ponownie</button>
                                {user.role === 'admin' && <button className={user.role === 'admin' ? "back-button back-button-extended" : "back-button"} onClick={()=>getData('*')}>Dostęp administracyjny</button>}
                            </>}                     
                        </div>
                    }
                    {(jobs.length > 0 && !active.job_date) && 
                        <div className="job-choose-outer-wrapper outer-wrapper">
                            <div className="job-open-header">
                                <h2 className="job-open-heading">Otwórz zadanie pomiarowe</h2>
                                <h3 className="job-open-sub-heading"><span>wybierz datę zadania</span></h3>
                            </div>
                            <div className="job-list-wrapper">
                                {jobsDates.sort((a, b) => a > b ? 1 : -1).map((job, index) =>
                                    <div key={index}>
                                        <p className="job-open-name"><button className="job-open-button" onClick={()=>setActiveSelection('job_date', job)}>{job}</button></p>
                                    </div>
                                )}
                                {active.cancel && <button className="back-button" onClick={()=>cancelJobChange()}>&#8592; Anuluj</button>}
                            </div>
                        </div>
                    }
                    {(active.job_date && !active.job_number) &&
                        <div className="job-choose-outer-wrapper outer-wrapper">
                            <div className="job-open-header">
                                <h2 className="job-open-heading">Otwórz zadanie pomiarowe</h2>
                                <h3 className="job-open-sub-heading"><span>wybierz numer zadania</span></h3>
                            </div>
                            <div className="job-list-wrapper">
                                {jobsNames.sort((a, b) => a > b ? 1 : -1).map((job, index) =>
                                    <div key={index}>
                                        <p className="job-open-name"><button className="job-open-button" onClick={()=>setActiveSelection('job_number', job)}>Zadanie nr {job}</button></p>
                                    </div>
                                )}
                                <button className="back-button" onClick={()=>setActive({...active, job_date:''})}>&#8592; Powrót</button>
                            </div>
                        </div>
                    }
                    {(active.job_date && active.job_number) && 
                        <div id="main-outside-wrapper" className="outer-wrapper">
                            {appLayer < 400 && <>
                            <div className="main-header-info">
                                <h2 className="job-open-heading">Aktywne zadanie pomiarowe</h2>
                                <h3 className="job-open-sub-heading"><span>{active.job_number}/{formattedDate}</span></h3>
                            </div>
                            {active.train_number && 
                            <div className="main-header-info">
                                <h2 className="job-open-heading">Aktywny pociąg</h2>
                                <h3 className="job-open-sub-heading"><span>{active.relation}<br></br>{active.start_hour} - {active.end_hour}</span></h3>
                                
                            </div>}
                            </>}
                        {appLayer === 100 && 
                            <div id="main-icon-middle-wrapper">
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(200)}>
                                    <div className="ic-wrap">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon2/>
                                        </div>
                                    </div>
                                    <div className="main-icon-text-wrapper">
                                        <p className="main-icon-description">Wybór pociągu</p>
                                    </div>
                                </div>
                                {active.train_id !== '' && 
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(300)}>
                                    <div className="ic-wrap">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon3/>
                                        </div>
                                    </div>
                                    <div className="main-icon-text-wrapper">
                                        <p className="main-icon-description">Pomiary</p>
                                    </div>
                                </div>
                                }
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(400)}>
                                    <div className="ic-wrap">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon4/>
                                        </div>
                                    </div>
                                    <div className="main-icon-text-wrapper">
                                        <p className="main-icon-description">Ważne informacje</p>
                                    </div>
                                </div>
                                <div className="main-icon-outer-wrapper" onClick={() => setActiveLayer(500)}>
                                    <div className="ic-wrap">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon5/>
                                        </div>
                                    </div>
                                    <div className="main-icon-text-wrapper">
                                        <p className="main-icon-description">Instrukcja obsługi</p>
                                    </div>
                                </div>
                                {user.role === 'admin' && 
                                <div className="main-icon-outer-wrapper">
                                    <Link to="../admin">
                                        <div className="ic-wrap">
                                            <div className="main-icon-inner-wrapper">
                                                <Icon6/>
                                            </div>
                                        </div>
                                        <div className="main-icon-text-wrapper">
                                            <p className="main-icon-description">Panel admina</p>
                                        </div>
                                    </Link>
                                </div>
                                }
                                <div className="main-icon-outer-wrapper" onClick={logout}>
                                    <div className="ic-wrap">
                                        <div className="main-icon-inner-wrapper">
                                            <Icon7/>
                                        </div>
                                    </div>
                                    <div className="main-icon-text-wrapper">
                                        <p className="main-icon-description">Wyloguj się</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {appLayer === 200 &&
                            <div id="train-details-tables-wrapper">
                            {activeRides.map((ride, index) =>
                                <div key={index}>
                                    <table className="train-details-table">
                                        <thead>
                                            <tr>
                                                <th>Pozycja w zadaniu</th>
                                                <td>{ride.position}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/*<tr>
                                                <th>Numer pociągu</th>
                                                <td>{ride.train_number}</td>
                                            </tr>*/}
                                            <tr>
                                                <th>Relacja pociągu</th>
                                                <td>{ride.begin_station} - {ride.end_station}</td>
                                            </tr>
                                            <tr>
                                                <th>Mierzony na trasie</th>
                                                <td>{ride.first_station_name} - {ride.last_station_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Łączna liczba wagonów</th>
                                                <td>{ride.total_cars}</td>
                                            </tr>
                                            <tr>
                                                <th>Wagony do pomiaru</th>
                                                <td>{ride.first_car === ride.last_car ? 'wagon nr ' + ride.first_car : `${ride.first_car} - ${ride.last_car}`}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{ride.status}</td>
                                            </tr>
                                            {ride.comments && <tr>
                                                <th>Uwagi</th>
                                                <td><span className="ride-comments">{ride.comments}</span></td>
                                            </tr>}
                                            <tr>
                                                <th>Rozkład jazdy</th>
                                                <td><button className="train-details-show-button" id={"train-details-show-button-"+index} onClick={()=>trainDetailsToggle(index)}>Pokaż</button></td>
                                            </tr>
                                            <tr className="train-is" id={"train-details-inner-section-"+index}>
                                                <td colSpan="2">
                                                    {activeTrains.filter(train => train.train_id === ride.train_id).map((train, index) => 
                                                        <section key={index}>
                                                            <p className="train-details-inner-section-header-wrapper">
                                                                <span className="train-details-inner-section-header section-40">Stacja kolejowa</span>
                                                                <span className="train-details-inner-section-header section-20">Przyjazd</span>
                                                                <span className="train-details-inner-section-header section-20">Odjazd</span>
                                                                <span className="train-details-inner-section-header section-20">Peron<br></br>/tor</span>
                                                            </p>
                                                        {train.stations && train.stations.map((station, index) => 
                                                            <p className="train-details-inner-section-table-wrapper" key={index}>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-40 station-important" : "train-details-inner-section-table section-40"}>{station[1]}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[2] ? station[2] : '-'}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[3] ? station[3] : '-'}</span>
                                                                <span className={(station[0] === ride.first_station_id || station[0] === ride.last_station_id) ? "train-details-inner-section-table section-20 station-important" : "train-details-inner-section-table section-20"}>{station[4] ? station[4] : 'b.d.'}/{station[5] ? station[5] : 'b.d.'}</span>
                                                            </p>  
                                                        )}
                                                        </section>
                                                    )}
                                                    <button className="train-details-show-button show-button-second" onClick={()=>trainDetailsToggle(index)}>Ukryj</button>            
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <button className="train-set-measurements-button" onClick={()=>activateTrain(ride.train_id)}>
                                                        <span className="train-set-measurement-svg-container"><Icon2/></span>
                                                        <span className="train-set-measurement-label">Wybierz pociąg</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                 
                                </div>
                            )}
                            </div>
                        }
                        {appLayer === 300 &&
                            <div id="measurements-layer-outer-wrapper">
                                {!active.train_number && 
                                    <div className="waiting-wrapper">                       
                                        <p className="waiting-message">Przed rozpoczęciem pomiaru należy najpierw wybrać pociąg</p>
                                        <button className="train-set-measurements-button" onClick={()=>setActiveLayer(200)}>
                                            <span className="train-set-measurement-svg-container"><Icon2/></span>
                                            <span className="train-set-measurement-label">Wybór pociągu</span>
                                        </button>    
                                    </div>
                                }
                                {active.train_number && 
                                    <div>
                                        {measurementStations.map((station, index) =>
                                            <div id={'train-measurements-outer-wrapper-'+index} key={index}>
                                                <div className="train-measurements-inner-wrapper" id={"train-measurements-wrapper-"+index}>
                                                    <div className="train-measurements-inner-header-wrapper">
                                                        <h3 className="train-measurements-inner-heading">Mierzony odcinek</h3>
                                                        <p className="train-measurements-inner-sub-heading">{index % 2 === 0 ? 'Stacja ' : 'Przejazd '}{station[1]}</p>
                                                        {index % 2 === 0 && <p className="train-measurements-inner-sub-heading">Według rozkładu o {(index < (measurementStations.length -1)) ? station[3] : station[2]}</p>}
                                                        {index % 2 !== 0 && <p className="train-measurements-inner-sub-heading">Według rozkładu pomiędzy {station[2]}</p>}
                                                        {/*<p className="train-measurements-inner-sub-heading">{index % 2 === 0 ? 'Według rozkładu o ' : 'Według rozkładu pomiędzy '}{(index !== 0 && active.measured_from_start === true) ? station[3] : station[2]}</p>*/}
                                                        <div className="train-measurements-controls-wrapper">
                                                            <button className={index !== 0 ? "change-measurement-stage-button" : "change-measurement-stage-button button-hidden"} onClick={()=>changeCurrentStation('minus', index)}><span></span><span></span></button>
                                                            <button className={(index !== measurementStations.length -1 && index <= Math.max(...completedMeasurements)) ? "change-measurement-stage-button" : "change-measurement-stage-button button-hidden"} onClick={()=>changeCurrentStation('plus', index)}><span></span><span></span></button>
                                                        </div>
                                                    </div>
                                                    {(index % 2 === 0) && 
                                                        <div className="train-measurements-main-wrapper">
                                                            <div className="train-measurements-inner-flex-wrapper">
                                                                <div className="train-measurements-inner-container">
                                                                    <div className="train-measurements-inner-left-wrapper">
                                                                        <p className="train-measurement-type-title">&nbsp;Wsiadło&nbsp;</p>
                                                                    </div>
                                                                    <div className="train-measurements-inner-right-wrapper">
                                                                        <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-1'], 'decrement', index, null, 'enter')}>–</button>
                                                                        <input 
                                                                            className="input-number" 
                                                                            type="number" 
                                                                            min="0" 
                                                                            onChange={(e) =>measurementFormChange(e, index, null)}
                                                                            id={[station[0]+'-'+index+'-1']}
                                                                            name={[station[0]+'-'+index+'-1']}
                                                                            value={measurementFormData[station[0]+'-'+index+'-1'] || ''}
                                                                            //value={measurementFormData[station[0]+'-'+index+'-1']  !== undefined ? measurementFormData[station[0]+'-'+index+'-1']  : ''}
                                                                        />
                                                                        <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-1'], 'increment', index, null, 'enter')}>+</button>
                                                                    </div>
                                                                </div>
                                                                <div className="train-measurements-inner-container">
                                                                    <div className="train-measurements-inner-left-wrapper">
                                                                        <p className="train-measurement-type-title">Wysiadło</p>
                                                                    </div>
                                                                    <div className="train-measurements-inner-right-wrapper">
                                                                        <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-2'], 'decrement', index, null, 'exit')}>–</button>
                                                                        <input 
                                                                            className="input-number" 
                                                                            type="number" 
                                                                            min="0" 
                                                                            onChange={(e) =>measurementFormChange(e, index, null)}
                                                                            id={[station[0]+'-'+index+'-2']}
                                                                            name={[station[0]+'-'+index+'-2']}
                                                                            value={measurementFormData[station[0]+'-'+index+'-2'] || ''}
                                                                            //value={measurementFormData[station[0]+'-'+index+'-2']  !== undefined ? measurementFormData[station[0]+'-'+index+'-2']  : ''}
                                                                        />
                                                                        <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-2'], 'increment', index, null, 'exit')}>+</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!(index % 2 === 0) && 
                                                        <div className="train-measurements-main-wrapper" key={index}>
                                                            <div className="train-measurements-inner-flex-wrapper">
                                                                <div className="train-measurements-inner-container">
                                                                    <div className="train-measurements-inner-left-wrapper">
                                                                        <img
                                                                            src={TrainFront}
                                                                            alt='train'
                                                                            className="train-graphic"
                                                                        />
                                                                    </div>
                                                                    <div className="train-measurements-inner-right-wrapper train-measurements-inner-right-heading">
                                                                        <p id="train-measurements-sub-heading">&#8673;</p>
                                                                        <h1 id="train-measurements-heading">Kierunek jazdy pociągu</h1>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {[...Array(active.last_car - active.first_car +1)].map(
                                                                (val, ind) =>
                                                                <div className="train-measurements-inner-flex-wrapper" key={ind}>
                                                                    <div className="train-measurements-inner-container">
                                                                        <div className="train-measurements-inner-left-wrapper">
                                                                            <span className="train-measurements-car-number">{active.first_car + ind}</span>
                                                                            <img
                                                                                src={TrainCar}
                                                                                alt='train'
                                                                                className="train-graphic"
                                                                            />
                                                                        </div>
                                                                        <div className="train-measurements-inner-right-wrapper">
                                                                            <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-car-'+(active.first_car+ind)], 'decrement', index, ind, 'car')}>–</button>
                                                                            <input 
                                                                                className={measurementFormData[station[0]+'-'+index+'-car-'+(active.first_car+ind)] === '-1' ? "input-number input-number-skipped" : "input-number"} 
                                                                                type="number" 
                                                                                min="-9" 
                                                                                onChange={(e) =>measurementFormChange(e, index, ind)}
                                                                                id={[station[0]+'-'+index+'-car-'+(active.first_car+ind)]}
                                                                                name={[station[0]+'-'+index+'-car-'+(active.first_car+ind)]}
                                                                                value={measurementFormData[station[0]+'-'+index+'-car-'+(active.first_car+ind)] || ''}
                                                                                //value={measurementFormData[station[0]+'-'+index+'-car-'+(active.first_car+ind)]  !== undefined ? measurementFormData[station[0]+'-'+index+'-car-'+(active.first_car+ind)]  : ''}
                                                                            />
                                                                            <button className="input-number-button" onClick={()=>changeMeasurementFormValue([station[0]+'-'+index+'-car-'+(active.first_car+ind)], 'increment', index, ind, 'car')}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    <div className="train-measurements-additional-fields-outer-wrapper">
                                                        <div className="train-measurements-checkbox-wrapper">
                                                            {<p className={measurementFormData[station[0]+'-'+index+'-checkbox'] === true ? "train-measurements-checkbox-label train-measurements-checkbox-checked-label" : "train-measurements-checkbox-label"} onClick={()=>changeCheckbox(station[0], index)}>pomiar szacunkowy?
                                                                <input 
                                                                    type ="checkbox" 
                                                                    onChange={measurementFormChange}
                                                                    className="train-measurements-checkbox train-measurements-checkbox-skewed" 
                                                                    id={[station[0]+'-'+index+'-checkbox']}
                                                                    name={[station[0]+'-'+index+'-checkbox']}
                                                                    value={measurementFormData[station[0]+'-'+index+'-checkbox'] || ''}
                                                                    //checked={measurementFormData[station[0]+'-'+index+'-checkbox']}
                                                                    checked={measurementFormData[station[0]+'-'+index+'-checkbox'] || false}
                                                                />
                                                                {/*<label className="train-measurements-checkbox-btn" data-tg-off="NIE" data-tg-on="TAK" htmlFor={[station[0]+'-'+index+'-checkbox']}></label>*/}
                                                                {<label className={measurementFormData[station[0]+'-'+index+'-checkbox'] ? "train-measurements-checkbox-btn train-measurements-checkbox-btn-checked" : "train-measurements-checkbox-btn"} data-tg={measurementFormData[station[0]+'-'+index+'-checkbox'] ? "TAK" : "NIE"} htmlFor={[station[0]+'-'+index+'-checkbox']}></label>}
                                                            </p>}
                                                        </div>
                                                        <div>
                                                            <div className="train-measurements-additional-flex-wrapper">
                                                                <div className="train-measurements-additional-left-column">
                                                                    <span className="train-measurements-inner-calculations-label">Zapełnienie wyliczone</span>
                                                                    <span className="train-measurements-inner-calculations-label">Zapełnienie wpisane</span>
                                                                    <span className="train-measurements-inner-calculations-label">Różnica</span>
                                                                </div>
                                                                <div className="train-measurements-additional-right-column">
                                                                    <div className="train-measurements-additional-input-wrapper">
                                                                        <input 
                                                                            className="input-number-readonly"
                                                                            readOnly 
                                                                            type="text" 
                                                                            id={[station[0]+'-fill-previous-'+index]}
                                                                            name={[station[0]+'-fill-previous-'+index]}
                                                                            //value={measurementFormData[station[0]+'-fill-previous-'+index] || 'b/d'}
                                                                            value={measurementFormData[station[0]+'-fill-previous-'+index] !== undefined ? measurementFormData[station[0]+'-fill-previous-'+index] : "b/d"}
                                                                        />
                                                                    </div>
                                                                    <div className="train-measurements-additional-input-wrapper">
                                                                        <input 
                                                                            className={(measurementFormData[station[0]+'-fill-calculated-'+index] === undefined || measurementFormData[station[0]+'-fill-calculated-'+index] === '-' || measurementFormData[station[0]+'-fill-calculated-'+index] >= 0) ? 'input-number-readonly input-calculated' : 'input-number-readonly input-calculated input-high-difference'}
                                                                            readOnly 
                                                                            type="text" 
                                                                            id={[station[0]+'-fill-calculated-'+index]}
                                                                            name={[station[0]+'-fill-calculated-'+index]}
                                                                            value={measurementFormData[station[0]+'-fill-calculated-'+index] !== undefined ? measurementFormData[station[0]+'-fill-calculated-'+index] : "-"}
                                                                        />
                                                                    </div>
                                                                    <div className="train-measurements-additional-input-wrapper">
                                                                        <input 
                                                                            className={(measurementFormData[station[0]+'-fill-difference-'+index] === undefined || measurementFormData[station[0]+'-fill-difference-'+index] === "-" || index%2 === 0 || measurementFormData[station[0]+'-fill-difference-'+index] < 10) ? 'input-number-readonly input-calculated' : 'input-number-readonly input-calculated input-high-difference'}
                                                                            readOnly 
                                                                            type="text" 
                                                                            id={[station[0]+'-fill-difference-'+index]}
                                                                            name={[station[0]+'-fill-difference-'+index]}
                                                                            value={measurementFormData[station[0]+'-fill-difference-'+index] !== undefined ? measurementFormData[station[0]+'-fill-difference-'+index] : '-'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="measurement-icon-outer-wrapper">
                                                        <div className="measurement-icon-container">
                                                            <label htmlFor={["capture-image-"+index]} className="measurement-icon-label">
                                                                <Icon8/>
                                                            </label>
                                                            <p className="measurement-icon-description">{measurementFormData["user_camera_photo-"+index] ? <span>Zastąp<br></br>zdjęcie</span> : <span>Zrób<br></br>zdjęcie</span>}</p>
                                                            <input accept="image/*" id={["capture-image-"+index]} className="capture-image" type="file" capture="environment" onChange={(e) => handlePhoto(index, e)}/>
                                                        </div>
                                                        <div className="measurement-icon-container">
                                                            <label htmlFor={["capture-images-"+index]} className="measurement-icon-label">
                                                                <Icon9/>
                                                            </label>
                                                            <p className="measurement-icon-description">Dołącz<br></br>serię zdjęć</p>
                                                            <input accept="image/*" id={["capture-images-"+index]} className="capture-image" type="file" multiple onChange={(e) => handlePhotos(index, e)}/>
                                                        </div>
                                                        <div className="measurement-icon-container">
                                                            <div className="measurement-icon-label" onClick={() => toggleCommentBox(station[0], index)}>
                                                                <Icon10/>
                                                            </div>
                                                            <p className="measurement-icon-description">{measurementFormData["additional-comments-active-"+index] ? <span>Usuń<br></br>komentarz</span> : <span>Dodaj<br></br>komentarz</span>}</p>
                                                        </div>
                                                    </div>
                                                    {measurementFormData["user_camera_photo-"+index] && 
                                                        <div className="user-image-preview-wrapper">
                                                            <div className="user-image-preview-container">
                                                                <img id={["user-image-preview-"+index]} className="image-preview" src={measurementFormData["user_camera_photo_url-"+index]} alt="user-input" /> 
                                                                <div className="user-image-remove" onClick={()=> removeCameraImage(index)}><Icon12/></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {measurementFormData["user_included_photos-"+index] && 
                                                        <table className="user-included-photos-table">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4}>Lista dołączonych zdjęć</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {measurementFormData["user_included_photos-"+index].map((photo, ix) =>
                                                                <tr key={ix}>
                                                                    <td>{ix+1}</td>
                                                                    <td>{photo.name}</td>
                                                                    <td><span className="user-included-photos-table-button" onClick={() => showPreviewPhoto(index, ix)}><Icon11/></span></td>
                                                                    <td><span className="user-included-photos-table-button" onClick={() => removeIncludedPhoto(index, ix)}><Icon12/></span></td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    }
                                                    {measurementFormData["additional-comments-active-"+index] && 
                                                        <div className="train-measurement-additional-comments-wrapper">
                                                            <p className="train-measurement-additional-comments-heading">Komentarz do pomiaru</p>
                                                            <textarea
                                                                className="train-measurement-additional-comments"
                                                                onChange={measurementFormChange}
                                                                id={[station[0]+'-additional-comments-'+index]}
                                                                name={[station[0]+'-additional-comments-'+index]}
                                                                value={measurementFormData[station[0]+'-additional-comments-'+index]}
                                                            />
                                                        </div>
                                                    }
                                                    <div id={"train-measurements-footer-"+index} className="train-measurements-footer-wrapper">
                                                        <p className="train-measurements-form-error" id={"train-measurements-form-error-"+index}></p>
                                                        <button className={(index !== measurementStations.length -1) ? "train-measurements-form-button" : "train-measurements-form-button train-measurements-form-button-finish"} onClick={()=>acceptMeasurementForm(station[0],index,false)}>{index !== (measurementStations.length -1) ? 'Zapisz pomiar' : 'Zakończ pomiary'}</button>
                                                        {index % 2 !== 0 && !completedMeasurements.includes(index) && <button className="train-measurements-form-skip-button" onClick={()=>skipMeasurement(station[0],index)}>Pomiń pomiar</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        }
                        {appLayer === 400 && 
                            <div className="instruction-main-container">
                                <h1 className="instruction-main-title">Informacje do wybranego zadania</h1>
                                {activeJob.length > 0 && activeJob[0].comments && 
                                    <>
                                        <p className="instruction-paragraph">Uwagi do zadania:</p>
                                        <ul className="instruction-list instruction-list-individual">
                                            <li>{activeJob[0].comments}</li>
                                        </ul> 
                                    </>
                                }
                                {activeRides.map((ride, index) =>
                                    <div key={index}>
                                        {ride.comments && <>
                                        <p className="instruction-paragraph">W pociągu badanym na trasie {ride.first_station_name} - {ride.last_station_name}(pozycja #{ride.position} w zadaniu):</p>
                                        <ul className="instruction-list instruction-list-individual">
                                            <li>{ride.comments}</li>
                                        </ul>   
                                        </>}       
                                    </div>
                                )}
                                <h1 className="instruction-main-title">Ogólne zasady pracy</h1>
                                <p className="instruction-paragraph">Przed rozpoczęciem pracy upewnij się, że wybrałeś/aś prawidłowe zadanie i pociąg.</p>
                                <p className="instruction-paragraph">Pomiary na stacjach są obowiązkowe w każdym przypadku.</p>
                                <p className="instruction-paragraph">Pomiary na przejazdach między stacjami są obowiązkowe gdy:</p>
                                    <ul className="instruction-list">
                                        <li>rozpoczynasz pomiar w pociągu</li>
                                        <li>na poprzedniej stacji nastąpiła wymiana większej liczby niż 30 pasażerów</li>
                                        <li>kończysz pomiar w pociągu</li>
                                    </ul>
                                <p className="instruction-paragraph">Jeśli masz problem z dokładnym pomiarem(duża liczba pasażerów) ustaw opcję “pomiar szacunkowy” na "TAK" dla danego etapu swojej pracy.</p>
                                <p className="instruction-paragraph">W przypadku gdy nie możesz przeliczyć liczby osób znajdujących się w wagonie wpisz w przeznaczone dla tego wagonu pole formularza wartość "-1".</p>
                                <p className="instruction-paragraph">Jeśli edytujesz wcześniejszy pomiar pamiętaj aby zapisać wyniki przyciskiem "zapisz pomiar"!</p>
                            </div>
                        }
                        {appLayer === 500 && 
                            <div className="instruction-main-container">
                                <h1 id="instruction-menu-0" className="instruction-main-title">Instrukcja obsługi aplikacji</h1>
                                <h3 className="instruction-main-sub-title">Badania Ruchu</h3>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-1" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#1</span><p className="instruction-menu-paragraph">Menu główne</p></div>
                                        {activeInstruction !== 1 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(1)}><span></span><span></span></button>}
                                        {activeInstruction === 1 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-1" className={activeInstruction === 1 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">W menu głównym aplikacji dostępne są następujące opcje:</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Icon1/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Zmiana zadania</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">W tym miejscu zmienisz zadanie ankieterskie.</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Hamburger/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Menu główne</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">Wyświetla ekran główny aplikacji.</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Icon2/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Wybór pociągu</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">Tutaj wybierzesz pociąg do pomiaru oraz sprawdzisz szczegóły dotyczące każdego pociągu w Twoim zadaniu.</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Icon3/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Pomiary</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">W tym miejscu wpiszesz swoje pomiary, prześlesz zdjęcia i dołączysz komentarze.</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Icon4/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Ważne informacje</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">Znajdziesz tutaj ważne adnotacje i wskazówki dotyczące Twojego zadania ankieterskiego.</p>
                                        <div className="instruction-outer-columns-wrapper">
                                            <div className="instruction-left-inner-wrapper">
                                                <Icon5/>
                                            </div>
                                            <div className="instruction-right-inner-wrapper">
                                                <p className="instruction-paragraph-highlighted">Instrukcja obsługi</p>
                                            </div>
                                        </div>
                                        <p className="instruction-paragraph">Pokazuje instrukcję obsługi aplikacji czyli część, w której się aktualnie znajdujesz.</p>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-2" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#2</span><p className="instruction-menu-paragraph">Wybór pociągu</p></div>
                                        {activeInstruction !== 2 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(2)}><span></span><span></span></button>}
                                        {activeInstruction === 2 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-2" className={activeInstruction === 2 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Po rozpoczęciu pracy i wyborze zadania pomiarowego zostaniesz przeniesiony do ekranu, w którym znajdziesz informacje o wszystkich pociągach przypisanych do aktywnego zadania.</p>
                                        <p className="instruction-paragraph">W razie konieczności zadanie pomiarowe możesz w każdej chwili zmienić klikając przycisk “zmiana zadania” znajdujący się w górnej części ekranu.</p>
                                        <img className="instruction-image" src={Instruction01} alt="instruction"></img>
                                        <p className="instruction-paragraph">Każdy pociąg przypisany do aktywnego zadania pomiarowego będzie wyszczególniony w tabeli. Znajdziesz tu następujące informacje</p>
                                        <ul className="instruction-list">
                                            <li>relacja(stacja początkowa – stacja końcowa)</li>
                                            <li>trasa pomiaru(stacja początkowa – stacja końcowa)</li>
                                            <li>łączna liczba wagonów</li>
                                            <li>wagony objęte przez Ciebie pomiarem</li>
                                            <li>status(czy pomiar został rozpoczęty/zakończony)</li>
                                            <li>rozkład jazdy(dostępny po kliknięciu w przycisk “pokaż”)</li>
                                        </ul>
                                        <img className="instruction-image" src={Instruction02} alt="instruction"></img>
                                        <p className="instruction-paragraph">{'Po zapoznaniu się ze szczegółami należy zgodnie z harmonogramem pracy wybrać i aktywować pociąg za pomocą przycisku “wybierz pociąg”(patrz wyżej).'}</p>
                                        <p className="instruction-paragraph">Po aktywacji aplikacja przejdzie do ekranu pomiarów. Informacje na temat wybranego pociągu znajdziesz w górnej części. W razie dokonania nieprawidłowego wyboru możesz zmienić pociąg przez kliknięcie w przycisk “zmień pociąg”.</p>
                                        <img className="instruction-image" src={Instruction03} alt="instruction"></img>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-3" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#3</span><p className="instruction-menu-paragraph">Rozkład jazdy pociągu</p></div>
                                        {activeInstruction !== 3 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(3)}><span></span><span></span></button>}
                                        {activeInstruction === 3 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-3" className={activeInstruction === 3 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Po kliknięciu w przycisk “pokaż”(opcja dostępna na ekranie wyboru pociągu) rozkład jazdy pociągu zostanie rozwinięty. Znajdziesz tam informacje o godzinach przyjazdu/odjazdu, a także peronach i torach.</p>
                                        <img className="instruction-image" src={Instruction04} alt="instruction"></img>
                                        <p className="instruction-paragraph">Pamiętaj, że informacje te są pobrane od przewoźnika, ale mogą wystąpić sytuacje, które spowodują zmiany w rozkładzie. Dlatego też zawsze należy zweryfikować  na właściwej stacji czy dane nie uległy zmianie.</p>
                                        <p className="instruction-paragraph">Stacje na których rozpoczynasz i kończysz swoje pomiary będą wyróżnione czerwonym kolorem dzięki czemu możesz łatwo określić planowany czas pracy dla wybranego pociągu.</p>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-4" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#4</span><p className="instruction-menu-paragraph">Pomiary</p></div>
                                        {activeInstruction !== 4 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(4)}><span></span><span></span></button>}
                                        {activeInstruction === 4 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-4" className={activeInstruction === 4 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">W części pomiarowej znajdziesz kolejno:</p>
                                        <ul className="instruction-list">
                                            <li>informacje o badanym odcinku trasy</li>
                                            <li>planowaną godzinę przyjazdu/przejazdu</li>
                                            <li>formularz pomiarowy(szerzej opisany poniżej)</li>
                                        </ul>
                                        <img className="instruction-image" src={Instruction05} alt="instruction"></img>
                                        <p className="instruction-paragraph">Formularz pomiarowy składa się z:</p>
                                        <ul className="instruction-list">
                                            <li>pól do wpisywania pomiarów</li>
                                            <li>przycisku “pomiar szacunkowy”</li>
                                            <li>informacji o aktualnym zapełnieniu pociągu</li>
                                            <li>przycisku “zrób zdjęcie”</li>
                                            <li>przycisku “dołącz serię zdjęć”</li>
                                            <li>przycisku “dodaj komentarz”</li>
                                            <li>przycisku “zapisz pomiar”</li>
                                            <li>przycisku “pomiń pomiar”(opcjonalnie)</li>
                                        </ul>
                                        <img className="instruction-image" src={Instruction06} alt="instruction"></img>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-5" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#5</span><p className="instruction-menu-paragraph">Przebieg pomiarów</p></div>
                                        {activeInstruction !== 5 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(5)}><span></span><span></span></button>}
                                        {activeInstruction === 5 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-5" className={activeInstruction === 5 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Pomiar zapełnienia odbywa się w schemacie:</p>
                                        <ul className="instruction-list">
                                            <li>stacja</li>
                                            <ul className="instruction-list">
                                                <li>policzenie liczby osób wsiadających</li>
                                                <li>policzenie liczby osób wysiadających</li>
                                            </ul>
                                            <li>przejazd między stacjami</li>
                                            <ul className="instruction-list">
                                                <li>policzenie liczby osób w każdym wagonie objętym pomiarem</li>
                                            </ul>
                                        </ul>
                                        <img className="instruction-image" src={Instruction07} alt="instruction"></img>
                                        <p className="instruction-paragraph">Pomiary na stacjach są obowiązkowe natomiast pomiary dla przejazdów powinny być przeprowadzone każdorazowo w przypadku:</p>
                                        <ul className="instruction-list">
                                            <li>pierwszego przejazdu po rozpoczęciu pracy w pociągu</li>
                                            <li>gdy na poprzedniej stacji nastąpiła wymiana większej niż 30 liczby pasażerów</li>
                                            <li>ostatniego przejazdu przed końcem pracy w pociągu</li>
                                        </ul>
                                        <p className="instruction-paragraph">Liczba policzonych osób powinna być wpisana w odpowiednie pole formularza. W przypadku problemów z dokładnym pomiarem(duża liczba pasażerów) należy zaznaczyć opcję “pomiar szacunkowy” – poprzez kliknięcie w obszar przycisku opcja “NIE” zmieni się na TAK.</p>
                                        <img className="instruction-image" src={Instruction08} alt="instruction"></img>
                                        <p className="instruction-paragraph">W przypadku potrzeby dołączenia zdjęć do pomiaru można je zrobić bezpośrednio(przycisk “zrób zdjęcie”) lub dołączyć zdjęcia znajdujące się już w pamięci urządzenia(przycisk “dołącz serię zdjęć”).</p>
                                        <p className="instruction-paragraph">Do pomiaru można również dołączyć swój komentarz/notatkę. Po kliknięciu w przycisk “dodaj komentarz” pojawi się pole tekstowe, w którym można umieścić taki wpis.</p>
                                        <img className="instruction-image" src={Instruction09} alt="instruction"></img>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-6" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#6</span><p className="instruction-menu-paragraph">Dołączanie zdjęć</p></div>
                                        {activeInstruction !== 6 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(6)}><span></span><span></span></button>}
                                        {activeInstruction === 6 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-6" className={activeInstruction === 6 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Jeśli dołączysz jedno lub większą liczbę zdjęć na ekranie pojawią się odpowiednie informacje.</p>
                                        <p className="instruction-paragraph">Dla zdjęć dołączonych z pamięci urządzenia możesz wybrać opcję podglądu przez kliknięcie w przycisk z ikoną lupy.</p>
                                        <img className="instruction-image" src={Instruction10} alt="instruction"></img>
                                        <p className="instruction-paragraph">W każdym przypadku możesz usunąć plik przez wybranie przycisku z ikoną kosza.</p>
                                        <img className="instruction-image" src={Instruction11} alt="instruction"></img>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-7" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#7</span><p className="instruction-menu-paragraph">Zapełnienie pojazdu</p></div>
                                        {activeInstruction !== 7 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(7)}><span></span><span></span></button>}
                                        {activeInstruction === 7 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-7" className={activeInstruction === 7 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Po wyliczeniu i wpisaniu ilości osób znajdujących się w poszczególnych wagonach nastąpi przeliczenie tzw. zapełnienia(łącznej ilości osób w wagonach objętych pomiarem). Po zapisaniu pomiaru liczba ta będzie wyświetlana w dolnej części ekranu.</p>
                                        <img className="instruction-image" src={Instruction12} alt="instruction"></img>
                                        <p className="instruction-paragraph">Podsumowanie zapełnienia jest przedstawione w polach znajdujących się pod przyciskiem “pomiar szacunkowy”. Dzieli się ono na trzy wartości:</p>
                                        <ul className="instruction-list">
                                            <li>zapełnienie wyliczone(na poprzednim etapie)</li>
                                            <li>zapełnienie wpisane(na aktualnym etapie)</li>
                                            <li>różnica(wyliczona między powyższymi wartościami)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-8" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#8</span><p className="instruction-menu-paragraph">Zasady pomiarów</p></div>
                                        {activeInstruction !== 8 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(8)}><span></span><span></span></button>}
                                        {activeInstruction === 8 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-8" className={activeInstruction === 8 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph instruction-paragraph-important">Pomiary na stacjach są obowiązkowe w każdym przypadku.</p>
                                        <p className="instruction-paragraph">Podczas postoju pociągu na stacji należy kontrolować i wpisać sumaryczną liczbę osób wsiadających i wysiadających w odpowiednie pola. Jeśli wystąpią trudności z dokładnym pomiarem(duża liczba ludzi) ustaw opcję pomiar szacunkowy na TAK poprzez naciśnięcie przycisku.</p>
                                        <img className="instruction-image" src={Instruction13} alt="instruction"></img>
                                        <p className="instruction-paragraph">Jeśli zapełnienie pojazdu zostało wcześniej wyznaczone lub pomiar odbywa się na stacji początkowej, na której pociąg rozpoczyna swój bieg na ekranie pojawi się informacja o aktualnej ilości ludzi znajdujących się w wagonach.</p>
                                        <p className="instruction-paragraph instruction-paragraph-important">Pomiary na przejazdach między stacjami są obowiązkowe gdy:</p>
                                        <ul className="instruction-list instruction-list-important">
                                            <li>rozpoczynasz pomiar w pociągu</li>
                                            <li>na poprzedniej stacji nastąpiła wymiana większej liczby niż 30 pasażerów</li>
                                            <li>kończysz pomiar w pociągu</li>
                                        </ul>
                                        <p className="instruction-paragraph">W przypadku gdy nie możesz przeliczyć jednego lub więcej wagonów należy wpisać w odpowiednie pole/pola wartość “-1”.</p>
                                        <img className="instruction-image" src={Instruction14} alt="instruction"></img>
                                        <p className="instruction-paragraph">W takiej sytuacji zapełnienie pojazdu nie zostanie wyznaczone i zostanie zachowana wartość z poprzednich pomiarów, jeśli jest dostępna.</p>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-9" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#9</span><p className="instruction-menu-paragraph">Pomijanie pomiarów</p></div>
                                        {activeInstruction !== 9 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(9)}><span></span><span></span></button>}
                                        {activeInstruction === 9 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-9" className={activeInstruction === 9 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Pomiary na przejazdach między stacjami możesz pominąć przez wciśnięcie przycisku “pomiń pomiar”.</p>
                                        <img className="instruction-image" src={Instruction15} alt="instruction"></img>
                                        <p className="instruction-paragraph">Jeśli pomiar nie jest wymagany aplikacja przejdzie od razu do kolejnego etapu. W sytuacji jeśli pomiar jest wymagany(zgodnie z warunkami opisanymi wcześniej) pojawi się komunikat z prośbą o podanie powodu, który uniemożliwia przeprowadzenie wymaganego pomiaru. Po wyborze i potwierdzeniu pomiar zostanie pominięty, a aplikacja przejdzie do kolejnego etapu.</p>
                                        <img className="instruction-image" src={Instruction16} alt="instruction"></img>
                                    </div>
                                </div>
                                <div className="instruction-main-wrapper">
                                    <div id="instruction-menu-10" className="instruction-menu-item">
                                        <div className="instruction-menu-heading"><span className="instruction-menu-span-left">#10</span><p className="instruction-menu-paragraph">Poprawa i kończenie pomiarów</p></div>
                                        {activeInstruction !== 10 && <button className="instruction-menu-item-button-normal" onClick={()=>setActiveInstruction(10)}><span></span><span></span></button>}
                                        {activeInstruction === 10 && <button className="instruction-menu-item-button-normal instruction-menu-item-button-rotated" onClick={()=>setActiveInstruction('')}><span></span><span></span></button>}
                                    </div>
                                    <div id="instruction-chapter-10" className={activeInstruction === 10 ? "instruction-chapter instruction-chapter-visible" : "instruction-chapter"}>
                                        <p className="instruction-paragraph">Jeśli zajdzie potrzeba można powrócić do wcześniej wykonanego pomiaru przez użycie przycisków w formie strzałek. </p>
                                        <img className="instruction-image" src={Instruction17} alt="instruction"></img>
                                        <p className="instruction-paragraph">UWAGA – każdorazowo po poprawieniu wartości należy zatwierdzić operację przyciskiem “zapisz pomiar”! Przejście do kolejnego/poprzedniego etapu za pomocą strzałek nie spowoduje zapisania nowych wartości!</p>
                                        <p className="instruction-paragraph">Aplikacja umożliwia przejście do dowolnego etapu pomiaru wykonanego wcześniej. Nie można natomiast przejść do kolejnego etapu zanim nie wykona się pomiaru/ewentualnie pominie pomiar na bieżącym etapie pracy.</p>
                                        <p className="instruction-paragraph">Gdy zostanie osiągnięty ostatni etap pomiaru przycisk zapisz pomiar zostanie zastąpiony przyciskiem “zakończ pomiary”. </p>
                                        <img className="instruction-image" src={Instruction18} alt="instruction"></img>
                                        <p className="instruction-paragraph">Po skończonych pomiarach aplikacja przejdzie do wyboru kolejnego pociągu.</p>
                                        <p className="instruction-paragraph instruction-paragraph-important">Jeśli wystąpi potrzeba edycji wcześniejszych pomiarów można wybrać pociąg ponownie i dokonać zmian. Każdorazowo należy pamiętać o tym by zapisać pomiar przyciskiem “zapisz pomiar”!</p>
                                    </div>
                                </div>
                            </div>
                        }
                            <div id="sticky-menu-outside">
                                <div className="sticky-menu-icon-wrapper" onClick={()=>setActive({...active, 'cancel': true, 'job_date':'','job_number':''})}>
                                    <Icon1/>
                                    <p className="sticky-menu-icon-description">Zmiana<br></br>zadania</p>
                                </div>
                                <div className={appLayer === 100 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"}  onClick={()=>setActiveLayer(100)}>
                                    <Hamburger/>
                                    <p className="sticky-menu-icon-description">Menu<br></br>główne</p>
                                </div>
                                
                                <div className={appLayer === 200 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(200)}>
                                    <Icon2/>
                                    <p className="sticky-menu-icon-description">Wybór<br></br>pociągu</p>
                                </div>
                                {active.train_id === '' && 
                                <div className="sticky-menu-icon-wrapper sticky-menu-icon-not-available">
                                    <Icon3/>
                                    <p className="sticky-menu-icon-description sticky-menu-icon-description-not-available">Pomiary</p>
                                </div>
                                }
                                {active.train_id !== '' &&
                                <div className={appLayer === 300 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(300)}>
                                    <Icon3/>
                                    <p className="sticky-menu-icon-description">Pomiary</p>
                                </div>
                                }
                                <div className='sticky-menu-icon-divider'></div>
                                <div className={appLayer === 400 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(400)}>
                                    <Icon4/>
                                    <p className="sticky-menu-icon-description">Ważne<br></br>inform.</p>
                                </div>
                                <div className={appLayer === 500 ? "sticky-menu-icon-wrapper sticky-menu-icon-active" : "sticky-menu-icon-wrapper"} onClick={()=>setActiveLayer(500)}>
                                    <Icon5/>
                                    <p className="sticky-menu-icon-description">Instr.<br></br>obsługi</p>
                                </div>
                            </div>
                            {appLayer === 300 && <div className="sticky-fill-display">
                                <p className="sticky-fill-display-info">Liczba osób w pojeździe: {(fill !== undefined && fill !== null) ? fill : 'brak danych'}</p>
                            </div>}
                        </div>
                    }
                </>}
            </div>
            <div id="modal-1" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(1)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div id="modal-1-inner-container" className="modal-inner-wrapper">
                        <img id="modal-preview-image" src='' alt="preview"></img>
                        <span id="modal-1-close-icon" className="modal-close-icon edit-user-icon" onClick={()=>closeModal(1)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                    </div>
                </div>
            </div>
            <div id="modal-2" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(2)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div className="modal-inner-wrapper">
                        <p id="modal-2-title">Pomijanie pomiaru</p>
                        <p id="modal-2-description"></p>
                        <div id="modal-2-inner-wrapper">
                            <label className="modal-2-label">
                                <input
                                    type="radio"
                                    value="za dużo osób"
                                    checked={selectedOption === "za dużo osób"}
                                    onChange={handleOptionChange}
                                />
                                W pojeździe znajduje się za dużo osób
                            </label>
                            <label className="modal-2-label">
                                <input
                                    type="radio"
                                    value="za mało czasu"
                                    checked={selectedOption === "za mało czasu"}
                                    onChange={handleOptionChange}
                                />
                                Zbyt mało czasu na wykonanie pomiaru
                            </label>
                        </div>
                        <div id="modal-2-buttons-wrapper">
                            <button id="modal-2-cancel-button" className="modal-2-button" onClick={()=>closeModal(2)}>Anuluj</button>
                            <button id="modal-2-proceed-button" className="modal-2-button" onClick={()=>sendSkip()}>Potwierdź</button>
                        </div>
                        <span className="modal-close-icon edit-user-icon" onClick={()=>closeModal(2)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                    </div>
                </div>
            </div>
        </div>
    );
};