import { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserContext } from './components/UserContext';
import { Common, Error, Login, Main, Admin } from './index';
import CacheBuster from 'react-cache-buster';
import Loading from './components/Loading';
import packageInfo from '../package.json';

export default function App() {
    
  useEffect(() => {
      document.title = 'Badania ruchu';
  }, []);

  const {user} = useContext(UserContext);

  const isProduction = process.env.NODE_ENV === 'production';

  // <Route path='/admin' element={<Admin />} />

  return (
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >

      <>
        {user &&
        <>
        {user.role === 'none' && (
          <BrowserRouter>
            <Routes>
                <Route path='/' element={<Common />}>
                  <Route index element={<Login />} />
                  <Route path='*' element={<Navigate to='/' />} />
                </Route>
            </Routes>
          </BrowserRouter>
        )}
        {user.role === 'user' && (
          <BrowserRouter>
            <Routes>
                <Route path='/' element={<Common />}>
                    <Route index element={<Main />} />
                    <Route path='*' element={<Error />} />
                </Route>
            </Routes>
          </BrowserRouter>
        )}
        {user.role === 'admin' && (
          <BrowserRouter>
            <Routes>
                <Route path='/' element={<Common />}>
                    <Route index element={<Main />} />
                    <Route path='/admin' element={<Admin />} />
                    <Route path='*' element={<Error />} />
                </Route>
            </Routes>
          </BrowserRouter>
        )}
        </>
        }
      </>

      </CacheBuster>        
  );
}