import React, { useState, useEffect, useContext } from "react";
import { UserContext, Axios } from './UserContext';
import { Link, useNavigate } from 'react-router-dom';
import {ReactComponent as Gear} from '../images/gear.svg';

export default function Admin(){

    // --- Użytkownik i wylogowanie ---

    const {user} = useContext(UserContext);

    //const {logout} = useContext(UserContext);

    const navigate = useNavigate();

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);
    
    const [appLayer, setAppLayer] = useState(false);

    // Dane użytkowników

    const [userList, setUserList] = useState([]);

    // Stan ikony edytowania

    const [activeEdit, setActiveEdit] = useState({});









    const test = true;

    function populateDB() {

        Axios.get('classes/populateDB.php', { timeout: 15000 }).then(function(response){
            if(response.data.message){
                document.querySelector('#modal3-error-description').innerHTML = response.data.message;
            } else {
                document.querySelector('#modal3-error-description').innerHTML = 'Proces zakończył się niepowodzeniem. Więcej szczegółów znajdziesz w konsoli.';
            }
        }).catch((error) => {
            console.log(error);
        });

    }












    // Pobieranie danych

    useEffect(() => {
        checkPriviledges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkPriviledges(){
        if (user.role !== 'admin') {
            navigate("/");
        } else {
            getUsers();
        }
    }

    function getUsers() {

        setUserList([]);

        setSearching(true);

        Axios.get('classes/users.php', { timeout: 5000 }).then(function(response){
            if(response.data){
                setUserList(response.data)
            }
            setSearching(false);
        }).catch((error) => {
            console.log(error);
            setSearching(false);
        });

    }

    // Formularze danych użytkowników

    const [userFormData, setUserFormData] = useState({});

    function userFormChange(event){
        
        const {name, value} = event.target;

        setUserFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
        
    }

    // Edycja danych użytkowników

    function setUserEditing(name, index, id){

        if(activeEdit[name+"-"+index] === true){
            setActiveEdit({ ...activeEdit, [name+"-"+index]: false});
        } else {
            setActiveEdit({ ...activeEdit, [name+"-"+index]: true});
        }

        let field = name+"-"+index;
        let ele = document.querySelector(["#"+field]);
        
        if(ele.offsetHeight > 0){

            let old_val = userList[index][name];
            let new_val = ele.value;

            if(!new_val){
                setUserFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        [field]: ''
                    }
                });
                return;
            }

            if(new_val !== old_val){
                let request = 'update';
                Axios.post('classes/users.php', { id, name, new_val, request }, { timeout: 5000 }).then(function(response){
                    if(response.data){
                        if(response.data === 'success'){
                            userList[index][name] = new_val;
                        } else if(response.data.message) {
                            setUserFormData(prevFormData => {
                                if(name === 'password'){
                                    return {
                                        ...prevFormData,
                                        [field]: ''
                                    }
                                } else {
                                    return {
                                        ...prevFormData,
                                        [field]: old_val
                                    }
                                }
                            });
                            document.querySelector('#modal-error-description').innerHTML = response.data.message;
                            showModal(1);
                        }
                    } 
                }).catch((error) => {
                    console.log(error);
                    setUserFormData(prevFormData => {
                        if(name === 'password'){
                            return {
                                ...prevFormData,
                                [field]: ''
                            }
                        } else {
                            return {
                                ...prevFormData,
                                [field]: old_val
                            }
                        }
                    });
                    document.querySelector('#modal-error-description').innerHTML = 'Wystąpił problem z połączeniem. Sprawdź czy jesteś online.';
                    showModal(1);
                });
            } 
            
        }
    
    }

    // Usuwanie użytkownika

    const [toDelete, setToDelete] = useState();

    function confirmDelete(id){
        setToDelete(id);
        showModal(2);
    }

    function deleteUser(){
        let id = toDelete;
        let ele = document.querySelector('#modal2-error-description');
        ele.innerHTML = '';
        Axios.delete('classes/users.php', { data: { id } }, { timeout: 5000 }).then(function(response){
            if(response.data){
                if(response.data === 'success'){
                    closeModal(2);
                    getUsers();
                } else if(response.data.message) {
                    ele.innerHTML = response.data.message;
                }
            } 
        }).catch((error) => {
            console.log(error);
            ele.innerHTML = 'Wystąpił problem z połączeniem. Sprawdź czy jesteś online.';
        });
    }

    // Pokazywanie i ukrywanie modali

    function showModal(num){
        let ele = document.querySelector(['#modal-'+num]);
        ele.classList.remove('modal-not-visible');
    }

    function closeModal(num){
        let ele = document.querySelector(['#modal-'+num]);
        ele.classList.add('modal-not-visible');
        document.querySelector('#modal-error-description').innerHTML = '';
        document.querySelector('#modal2-error-description').innerHTML = '';
    }

    // Dodawanie nowego użytkownika

    const [newUserFormData, setNewUserFormData] = useState({
        username: '',
        password: '',
        role: 'user',
        personal_id: '',
        phone_number: '',
        email: '',
        full_name: '',
        town: '',
        car_status: '',
        comments: ''
    });

    function newUserFormChange(event){
        
        const {name, value} = event.target;

        setNewUserFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
        
    }

    function verifyUsername(){

        let name = 'username';
        let ele = document.querySelector(['#new-user-table-error-username']);
        let value = newUserFormData.username;
        let request = 'check';

        if(value){
            Axios.post('classes/users.php', { name, value, request }, { timeout: 1000 }).then(function(response){
                if(response.data === 'success'){
                    ele.innerHTML = '';
                } else if(response.data.message){
                    ele.innerHTML = response.data.message;
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            ele.innerHTML = 'Wprowadź nazwę użytkownika';
        }

    }

    function verifyPassword(){

        let ele = document.querySelector(['#new-user-table-error-password']);
        let value = newUserFormData.password;
        
        if(value.length < 8){
            ele.innerHTML = 'Hasło musi mieć co najmniej 8 znaków.';
        } else {
            ele.innerHTML = '';
        }

    }

    function verifyPhoneNumber(){

        let ele = document.querySelector(['#new-user-table-error-phone_number']);
        let value = newUserFormData.phone_number;

        if(!value){
            ele.innerHTML = '';
            return;
        } else if(value.match(/\d/g)){
            if(value.match(/\d/g).length > 8){
                ele.innerHTML = '';
            } else {
                ele.innerHTML = 'Numer telefonu musi składać się z co najmniej 9 cyfr.';
            }
        } else {
            ele.innerHTML = 'Numer telefonu musi składać się z co najmniej 9 cyfr.';
        }

    }

    function verifyEmail(){

        let ele = document.querySelector(['#new-user-table-error-email']);
        let value = newUserFormData.email;

        if(!value){
            ele.innerHTML = '';
            return;
        } else if(value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            ele.innerHTML = '';
        } else {
            ele.innerHTML = 'Wpisz prawidłowy adres email lub pozostaw pole puste.';
        }

    }

    function verifyNewUserForm(){

        let ele = document.querySelector(['#new-user-table-error-general']);

        verifyUsername();
        verifyPassword();
        verifyPhoneNumber();
        verifyEmail();

        let ele1 = document.querySelector(['#new-user-table-error-username']);
        let ele2 = document.querySelector(['#new-user-table-error-password']);
        let ele3 = document.querySelector(['#new-user-table-error-phone_number']);
        let ele4 = document.querySelector(['#new-user-table-error-email']);

        if(ele1.offsetHeight > 0 || ele2.offsetHeight > 0 || ele3.offsetHeight > 0 || ele4.offsetHeight > 0){
            ele.innerHTML = 'W formularzu znaleziono błędy.<br>Popraw oznaczone pola i spróbuj ponownie.'
        } else {
            ele.innerHTML = '';
            addNewUser();
        }
    }

    const [userBackendErrors, setUserBackendErrors] = useState([]);

    function addNewUser(){

        setUserBackendErrors([]);

        let button = document.querySelector('#new-user-create-button');
        button.setAttribute("disabled", "disabled");

        let userData = newUserFormData;
        let request = 'add';
        
        Axios.post('classes/users.php', { request, userData }, { timeout: 5000 }).then(function(response){
            if(response.data === 'success'){
                getUsers();
                setAppLayer(100);
                setNewUserFormData({
                    username: '',
                    password: '',
                    role: 'user',
                    personal_id: '',
                    phone_number: '',
                    email: '',
                    full_name: '',
                    town: '',
                    car_status: '',
                    comments: ''
                });
                button.removeAttribute("disabled");
            } else if(response.data.message){
                setUserBackendErrors(response.data.message);
                showModal(1);
                button.removeAttribute("disabled");
            }
        }).catch((error) => {
            console.log(error);
            document.querySelector('#modal-error-description').innerHTML = 'Wystąpił problem z połączeniem. Sprawdź czy jesteś online.';
            showModal(1);
            button.removeAttribute("disabled");
        });

    }

    const [backupMessage, setBackupMessage] = useState('');

    function backupDB(){

        showModal(4);

        setBackupMessage("Proszę czekać, trwa kopiowanie bazy danych.")

        Axios.get('classes/backup/backup.php', { timeout: 15000 }).then(function(response){
            console.log(response);
            setBackupMessage(response.data);
        }).catch((error) => {
            console.log(error);
            setBackupMessage('Błąd sieciowy. Spróbuj później.');
        });

    }

    const {logout} = useContext(UserContext);

    return (
        <div id="app-outer-container">
            {userList.length === 0 && 
                <div className="waiting-wrapper">
                    {searching && 
                    <>
                        <p className="waiting-message">Wyszukiwanie użytkowników</p>
                        <Gear/>
                    </>}
                    {!searching && 
                    <>
                        <p className="waiting-message">Nie udało się pobrać listy użytkowników.<br></br><br></br>Sprawdź swoje połączenie internetowe i spróbuj ponownie.</p>
                        <button id="admin-check-again-button" className="back-button" onClick={()=>getUsers()}>Sprawdź ponownie</button>
                        <Link to="/"><button className="admin-choice-button">Aplikacja</button></Link>

                    </>}                     
                </div>
            }
            {userList.length > 0 &&
                <div id="admin-panel-outer-container">
                    <div id="admin-menu-wrapper">
                        <Link to="/"><button className="admin-choice-button">Aplikacja</button></Link>
                        {(!appLayer || appLayer !== 100) && <button className="admin-choice-button" onClick={()=>setAppLayer(100)}>Edycja użytkowników</button>}
                        {(!appLayer || appLayer !== 200) && <button className="admin-choice-button" onClick={()=>setAppLayer(200)}>Dodanie użytkownika</button>}
                        <button className="admin-choice-button" onClick={()=>backupDB()}>Kopia Bazy Danych</button>
                        <button className="admin-choice-button" onClick={logout}>Wyloguj się</button>
                    </div>
                    {appLayer === 100 && 
                        <div id="admin-user-list-outer-container">
                            {userList.map((current, index) =>
                                <div className="user-list-table-wrapper" key={index}>
                                    <table className={current.username !== user.name ? "user-list-table" : "user-list-table current-user-table"}>
                                        <thead>
                                            <tr>
                                                <th>Parametr</th>
                                                <th>Wartość</th>
                                                <th>Akcja</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Nazwa użytkownika</td>
                                                <td>
                                                    <p id={['p-username-'+index]} className={!activeEdit["username-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['username-'+index] || current.username}</p>
                                                    <input 
                                                        className={activeEdit["username-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['username-'+index]}
                                                        name={['username-'+index]}
                                                        value={userFormData['username-'+index] || current.username}
                                                    />
                                                </td>
                                                <td>{current.username !== user.name && 
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("username", index, current.id)}>
                                                        {!activeEdit["username-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>}
                                                    {current.username === user.name && <span className="action-not-available-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"></path></svg></span>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hasło</td>
                                                <td>
                                                    <p id={['p-password-'+index]} className={!activeEdit["password-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{'*****'}</p>
                                                    <input 
                                                        className={activeEdit["password-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['password-'+index]}
                                                        name={['password-'+index]}
                                                        value={userFormData['password-'+index]}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("password", index, current.id)}>
                                                        {!activeEdit["password-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Uprawnienia</td>
                                                <td>
                                                    <p id={['p-role-'+index]} className={!activeEdit["role-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['role-'+index] ? (userFormData['role-'+index] === 'user' ? 'użytkownik' : 'administrator') : (current.role === 'user' ? 'użytkownik' : 'administrator')}</p>
                                                    <select 
                                                        className={activeEdit["role-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        value={userFormData['role-'+index] || current.role} 
                                                        id={['role-'+index]}
                                                        name={['role-'+index]}
                                                        onChange={userFormChange}>
                                                        <option value="user">użytkownik</option>
                                                        <option value="admin">administrator</option>
                                                    </select>
                                                </td>
                                                <td>{current.username !== user.name && 
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("role", index, current.id)}>
                                                        {!activeEdit["role-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>}
                                                    {current.username === user.name && <span className="action-not-available-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"></path></svg></span>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Numer identyfikacyjny</td>
                                                <td>
                                                    <p id={['p-personal_id-'+index]} className={!activeEdit["personal_id-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['personal_id-'+index] || (current.personal_id || '-')}</p>
                                                    <input 
                                                        className={activeEdit["personal_id-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['personal_id-'+index]}
                                                        name={['personal_id-'+index]}
                                                        value={userFormData['personal_id-'+index] || current.personal_id}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("personal_id", index, current.id)}>
                                                        {!activeEdit["personal_id-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Numer telefonu</td>
                                                <td>
                                                    <p id={['p-phone_number-'+index]} className={!activeEdit["phone_number-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['phone_number-'+index] || (current.phone_number || '-')}</p>
                                                    <input 
                                                        className={activeEdit["phone_number-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['phone_number-'+index]}
                                                        name={['phone_number-'+index]}
                                                        value={userFormData['phone_number-'+index] || (current.phone_number || '')}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("phone_number", index, current.id)}>
                                                        {!activeEdit["phone_number-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Adres email</td>
                                                <td>
                                                    <p id={['p-email-'+index]} className={!activeEdit["email-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['email-'+index] || (current.email || '-')}</p>
                                                    <input 
                                                        className={activeEdit["email-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['email-'+index]}
                                                        name={['email-'+index]}
                                                        value={userFormData['email-'+index] || (current.email || '')}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("email", index, current.id)}>
                                                        {!activeEdit["email-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Imię i nazwisko</td>
                                                <td>
                                                    <p id={['p-full_name-'+index]} className={!activeEdit["full_name-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['full_name-'+index] || (current.full_name || '-')}</p>
                                                    <input 
                                                        className={activeEdit["full_name-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['full_name-'+index]}
                                                        name={['full_name-'+index]}
                                                        value={userFormData['full_name-'+index] || (current.full_name || '')}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("full_name", index, current.id)}>
                                                        {!activeEdit["full_name-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Miejscowość</td>
                                                <td>
                                                    <p id={['p-town-'+index]} className={!activeEdit["town-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['town-'+index] || (current.town || '-')}</p>
                                                    <input 
                                                        className={activeEdit["town-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['town-'+index]}
                                                        name={['town-'+index]}
                                                        value={userFormData['town-'+index] || (current.town || '')}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("town", index, current.id)}>
                                                        {!activeEdit["town-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Samochód</td>
                                                <td>
                                                    <p id={['p-car_status-'+index]} className={!activeEdit["car_status-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['car_status-'+index] || (current.car_status || 'brak informacji')}</p>
                                                    <select 
                                                        className={activeEdit["car_status-"+index] ? "user-edit-input" : "user-edit-input not-visible"}
                                                        value={userFormData['car_status-'+index] || (current.car_status || '')} 
                                                        id={['car_status-'+index]}
                                                        name={['car_status-'+index]}
                                                        onChange={userFormChange}>
                                                        <option value="tak">tak</option>
                                                        <option value="nie">nie</option>
                                                        {current.car_status === null && <option value="">brak informacji</option>}
                                                    </select>
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("car_status", index, current.id)}>
                                                        {!activeEdit["car_status-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Komentarz</td>
                                                <td>
                                                    <p id={['p-comments-'+index]} className={!activeEdit["comments-"+index] ? "table-paragraph" : "table-paragraph not-visible"}>{userFormData['comments-'+index] || (current.comments || '-')}</p>
                                                    <textarea
                                                        className={activeEdit["comments-"+index] ? "user-edit-input user-edit-textarea" : "user-edit-input user-edit-textarea not-visible"}
                                                        type="text" 
                                                        onChange={userFormChange}
                                                        id={['comments-'+index]}
                                                        name={['comments-'+index]}
                                                        value={userFormData['comments-'+index] || (current.comments || '')}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="edit-user-button" onClick={()=>setUserEditing("comments", index, current.id)}>
                                                        {!activeEdit["comments-"+index] 
                                                        ? 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2zM5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4 18 6.73l-2 1.95L13.32 6zm-8.9 8.91L12 7.32l2.7 2.7-5.6 5.6-3 .28z" data-name="edit-2"></path></g></svg>
                                                        </span> 
                                                        : 
                                                        <span className="edit-user-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"></path></svg>
                                                        </span>}
                                                    </button>
                                                </td>
                                            </tr>
                                            {current.username !== user.name && <tr>
                                                <th colSpan={2}><span className="">Usuń użytkownika</span></th>
                                                <td><button className="delete-user-button" onClick={()=>confirmDelete(current.id)}><span className="edit-user-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    }
                    {appLayer === 200 && 
                        <div>
                            <div className="user-list-table-wrapper">
                                <table className="user-list-table">
                                    <thead>
                                        <tr>
                                            <th>Parametr</th>
                                            <th>Wartość</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nazwa użytkownika*</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text" 
                                                        onChange={newUserFormChange}
                                                        id="username"
                                                        name="username"
                                                        value={newUserFormData.username}
                                                        onBlur={()=>verifyUsername()}
                                                    />
                                                </p>
                                                <p id="new-user-table-error-username" className="new-user-error-message"></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Hasło*</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="password"
                                                        onChange={newUserFormChange}
                                                        id="password"
                                                        name="password"
                                                        value={newUserFormData.password}
                                                        onBlur={()=>verifyPassword()}
                                                    />
                                                </p>
                                                <p id="new-user-table-error-password" className="new-user-error-message"></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Uprawnienia</td>
                                            <td><p>
                                                    <select 
                                                        className="user-edit-input"
                                                        value={newUserFormData.role}
                                                        id="role"
                                                        name="role"
                                                        onChange={newUserFormChange}>
                                                        <option value="user">użytkownik</option>
                                                        <option value="admin">administrator</option>
                                                    </select>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Numer identyfikacyjny</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text"
                                                        onChange={newUserFormChange}
                                                        id="personal_id"
                                                        name="personal_id"
                                                        value={newUserFormData.personal_id}
                                                    />
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Numer telefonu</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text"
                                                        onChange={newUserFormChange}
                                                        id="phone_number"
                                                        name="phone_number"
                                                        value={newUserFormData.phone_number}
                                                        onBlur={()=>verifyPhoneNumber()}
                                                    />
                                                </p>
                                                <p id="new-user-table-error-phone_number" className="new-user-error-message"></p>
                                            </td>
                                        </tr>  
                                        <tr>
                                            <td>Email</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text"
                                                        onChange={newUserFormChange}
                                                        id="email"
                                                        name="email"
                                                        value={newUserFormData.email}
                                                        onBlur={()=>verifyEmail()}
                                                    />
                                                </p>
                                                <p id="new-user-table-error-email" className="new-user-error-message"></p>
                                            </td>
                                        </tr> 
                                        <tr>
                                            <td>Imię i nazwisko</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text"
                                                        onChange={newUserFormChange}
                                                        id="full_name"
                                                        name="full_name"
                                                        value={newUserFormData.full_name}
                                                    />
                                                </p>
                                            </td>
                                        </tr>  
                                        <tr>
                                            <td>Miasto</td>
                                            <td><p>
                                                    <input 
                                                        className="user-edit-input"
                                                        type="text"
                                                        onChange={newUserFormChange}
                                                        id="town"
                                                        name="town"
                                                        value={newUserFormData.town}
                                                    />
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Samochód</td>
                                            <td><p>
                                                    <select 
                                                        className="user-edit-input"
                                                        value={newUserFormData.car_status}
                                                        id="car_status"
                                                        name="car_status"
                                                        onChange={newUserFormChange}>
                                                        <option value="">brak informacji</option>
                                                        <option value="nie">nie</option>
                                                        <option value="tak">tak</option>
                                                    </select>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Komentarz</td>
                                            <td><p>
                                                    <textarea
                                                        className="user-edit-input user-edit-textarea"
                                                        onChange={newUserFormChange}
                                                        id="comments"
                                                        name="comments"
                                                        value={newUserFormData.comments}
                                                    />
                                                </p>
                                            </td>
                                        </tr> 
                                        <tr>
                                            <td colSpan='2'>
                                                <button id="new-user-create-button" className="confirm-delete-user-button cdub-1" onClick={()=>verifyNewUserForm()}>Dodaj użytkownika</button>
                                                <p id="new-user-table-error-general"></p>
                                            </td>
                                        </tr>                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            }
            <div id="modal-1" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(1)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div className="modal-inner-wrapper">
                        <span className="modal-close-icon edit-user-icon" onClick={()=>closeModal(1)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                        <p className="modal-error-description">Nie udało się wykonać wpisu.</p>
                        <p className="modal-error-description" id="modal-error-description"></p>
                        {userBackendErrors.length > 0 && <ul id="backend-error-list">
                            {userBackendErrors.map((error, index) =>
                                <li key={index}>{error}</li>
                            )}
                        </ul>}
                    </div>
                </div>
            </div>
            <div id="modal-2" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(2)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div className="modal-inner-wrapper">
                        <span className="modal-close-icon edit-user-icon" onClick={()=>closeModal(2)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                        <p className="modal-error-description">Czy na pewno usunąć wybranego użytkownika?</p>
                        <div id="modal2-buttons-wrapper">
                            <button className="confirm-delete-user-button cdub-2" onClick={()=>deleteUser()}>Usuń</button>
                            <button className="confirm-delete-user-button cdub-1" onClick={()=>closeModal(2)}>Anuluj</button>
                        </div>
                        <p className="modal-error-description" id="modal2-error-description"></p>
                    </div>
                </div>
            </div>
            {test && <div id="modal-3" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(3)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div className="modal-inner-wrapper">
                        <span className="modal-close-icon edit-user-icon" onClick={()=>closeModal(3)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                        <p className="modal-error-description">Czy na pewno usunąć wszystkie dane z bazy danych i wygenerować dane testowe?</p>
                        <div id="modal3-buttons-wrapper">
                            <button className="confirm-delete-user-button cdub-2" onClick={()=>populateDB()}>Wykonaj</button>
                            <button className="confirm-delete-user-button cdub-1" onClick={()=>closeModal(3)}>Anuluj</button>
                        </div>
                        <p className="modal-error-description" id="modal3-error-description"></p>
                    </div>
                </div>
            </div>}
            <div id="modal-4" className="modal-outer-container modal-not-visible" onClick={()=>closeModal(4)}>
                <div className="modal-inner-container" onClick={(e)=>e.stopPropagation()}>
                    <div className="modal-inner-wrapper">
                        <span className="modal-close-icon edit-user-icon" onClick={()=>closeModal(4)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span>
                        <p className="modal-error-description">{backupMessage}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};  